import React, { useState, useEffect, ChangeEvent, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import moment from 'moment';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { IItemInwardSearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';


const DirectInwardOutletDetail = observer((): JSX.Element => {
  const { directInwardStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const { getDirectInwardMaster, directInwardList, getDirectInwardDetails, directInwardDetails, getDirectInwardLedgers, getDirectLedgers } = directInwardStore;  
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isInwardDetails, setInwardDetailsModal] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLedgers, setLedgers] = useState(false);

  const [inwardDetail, setInwardDetail] = useState<any>({})


  console.log(inwardDetail, 'inwardDetail>>')

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])
  const [ledgerData, setLedgerData] = useState<any[]>([]);

  const deleteInwardMaster = async (inwardNo) => {
    setLoading(true);
    const status = await directInwardStore.deleteDirectInwardMaster(inwardNo);

    if (status === 'Success') {
      fetchPreLoadngData();
      setLoading(false);
      setSuccessModal(true);
    } else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchPreLoadngData();
  }

  async function fetchPreLoadngData() {
    const userRights = await userCreationStore?.getUserRight('Direct_inward')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    await getDirectInwardMaster();
    setLoading(false);
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchPreLoadngData();
      isCurrentPage.current = false;
    }
    return () => { }
  }, []);

  const editItemInwardDetials = (inwardNo) => {
    navigate('/Admin/DirectInward/' + inwardNo);
  }

  const handleCreate = () => {
    navigate('/Admin/DirectInward')
  }

  const getInwardItem = async (inwardNo) => {
 
    const data = await getDirectInwardDetails(inwardNo);
    await getDirectInwardLedgers(inwardNo);
    const ledgers = await getDirectInwardLedgers(inwardNo);
    console.log(ledgers,'ledgers');
    setLedgerData((prevData) => {
      console.log(prevData, 'Previous ledger data'); 
      return [...ledgers]; 
    });
    console.log(ledgerData,'ledgerData');
    
    let findObj = directInwardList?.find((obj) => obj?.directInwardId === inwardNo)
    console.log(findObj,'findObj');
    
    setInwardDetail({ ...findObj })
    if (data !== undefined || data !== null) {
      setInwardDetailsModal(true);
    }
  }

  const handleClose = () => {
    setInwardDetailsModal(false);
    setLedgers(false);
  }

  // console.log(ledgers, 'ledgers');

  // const netAmount = useMemo(() => {
  //   let totalNetAmount: number = directInwardDetails?.length ? directInwardDetails.reduce((a, v) => a = a + v.inwardTotalValue!, 0) : 0;
  //   if (!ledgerData?.length) {
  //     if (inwardDetail.discountAmount && totalNetAmount && inwardDetail?.discountType !== "None") {
  //       return Math.round(totalNetAmount - (inwardDetail?.discountAmount ?? 0))
  //     } else {
  //       return Math.round(totalNetAmount)
  //     }
  //   } else if (ledgerData?.length) {
  //     return Math.round(totalNetAmount + ledgerData?.reduce((a, v) => a = a + v.directInwardLedgerAmount!, 0) - Number(inwardDetail.discountAmount ?? 0))
  //   }
  //   return 0;
  // }, [ledgerData?.length, inwardDetail, directInwardDetails])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (directInwardList && directInwardList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(directInwardList)
    }
  }, [directInwardList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(directInwardList, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> : null
      }
      <div className='container-fluid'>
        <div className='vertical-space-20'></div>
        <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={directInwardList}
          searchTitles={[{ key: "SupplierName", value: "Supplier Name" }, { key: "outletName", value: "Outlet Name" }]}
          emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
        <div className='vertical-space-20'></div>
        <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className='btnBox'>
            <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{directInwardList?.length} Records</button>
          </div>
          <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
            <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
          </Stack>
        </div>
        <div className='vertical-space-20'></div>
        <div className='tableListDetails'>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope='col'>Id</th>
                <th scope='col'>Date</th>
                <th scope='col'>Supplier</th>
                <th scope='col'>Outlet Name</th>
                <th scope='col'>Bill Number</th>
                <th scope='col'>Bill Date</th>
                <th scope='col'>Bill Amount</th>
                <th scope='col'></th>
                {/* <th scope='col'></th> */}
                <th scope='col'>Create </th>
                <th scope='col'>Update </th>
                <th scope='col'>Delete </th>
              </tr>
            </thead>
            <tbody>
              {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                (isSearch ? filteredData : currentPageData)?.map((val, key) => {

                  return (
                    <tr key={key}>
                      <td>{val.directInwardId}</td>
                      <td>{moment(val.Inwarddate).format('DD-MMM-YYYY')}</td>
                      <td>{val.SupplierName}</td>
                      <td>{val.outletName}</td>
                      <td>{val.billno}</td>
                      <td>{moment(val.billdate).format('DD-MMM-YYYY')}</td>
                      <td>{val.outletName}</td>
                      <td><button disabled={!userRights?.view} onClick={() => getInwardItem(val.directInwardId)}>Detail</button></td>
                      <td><button disabled={!userRights?.add} className='Add' onClick={handleCreate}><FontAwesomeIcon icon={faAdd} /></button></td>
                      <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editItemInwardDetials(val.directInwardId)} /></button></td>
                      <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteInwardMaster(val.directInwardId)} /></button></td>
                    </tr>
                  )

                })
              }
            </tbody>
          </table>
        </div>
        <Modal show={isInwardDetails} onHide={handleClose} className='PriceHistoryModel'>
          <Modal.Header closeButton>
            <Modal.Title>Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='tableBox'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">ItemName</th>
                    <th scope="col">Barcode</th>
                    <th scope="col">Batch No</th>
                    <th scope="col">Mfg Date  </th>
                    <th scope="col">Exp Date  </th>
                    <th scope="col">Bill Quantity </th>
                    <th scope="col">Free Quantity  </th>
                    <th scope="col">MRP</th>
                    <th scope="col">Pur. Rate</th>
                    <th scope="col">Discount % </th>
                    <th scope="col">Disc. Value </th>
                    <th scope="col">GST %</th>
                    <th scope="col">GST Value</th>
                    <th scope="col">Total Value</th>
                    {/* <th scope='col'>Delete </th> */}
                  </tr>
                </thead>
                <tbody>
                  {directInwardDetails?.length &&
                    directInwardDetails?.map((val, key) => {
                      return (
                        <tr key={key}>
                          <td>{val.inwardItemName}</td>
                          <td>{val.barcode}</td>
                          <td>{val.batchNo}</td>
                          <td>{moment(val.mfdate)?.format("DD-MMM-YYYY")}</td>
                          <td>{moment(val.expdate)?.format("DD-MMM-YYYY")}</td>
                          <td>{val.inwardItemQuantity}</td>
                          <td>{val.inwardFreeQuantiity}</td>
                          <td>{val.mrprate}</td>
                          <td>{val.inwardItemRate}</td>
                          <td>{val.inwardDisPercentage}</td>
                          <td>{val.inwardDiscountPerItem}</td>
                          <td>{val.inwardGstPercentage}</td>
                          <td>{val.inwardGstValue}</td>
                          <td>{val.inwardTotalValue}</td>
                          {/* <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteInwardDetail(val.inwardDetailNo)} /></button></td> */}
                        </tr>
                      )
                    })
                  }

                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Header>
            <Modal.Title>Discount Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='tableBox'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Discount Type</th>
                    <th scope="col">Discount % </th>
                    <th scope='col'>Discount Amount </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{inwardDetail.discountType}</td>
                    <td>{((Number(inwardDetail?.discountAmount ?? 0) / Number(inwardDetail?.grossvalue ?? 0)) * 100)?.toFixed(2)}</td>
                    <td>{inwardDetail.discountAmount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Header>
            <Modal.Title>Ledgers</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='tableBox'>
              <table className="table table-striped">
                <thead>
                  <tr>

                    <th scope="col">Id</th>
                    <th scope="col">Ledger Name </th>
                    <th scope="col">Ledger Amount </th>
                    {/* <th scope='col'>Delete </th> */}
                  </tr>
                </thead>
                <tbody>
               
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <h4>Net Amount:</h4>  {netAmount} */}
          </Modal.Footer>
        </Modal>
      </div>
      <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>
            <div className='imgBox'>
              <Image src={require('../../../gsmecom/images/checked.png')} />
            </div>
            <h4>Succesfully Deleted</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSuccessModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>
            <div className='imgBox'>
              <Image src={require('../../../gsmecom/images/warning.png')} />
            </div>
            <h4>Failed</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleFailureModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );

});

export default DirectInwardOutletDetail;
