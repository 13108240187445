import axios, { AxiosError, AxiosResponse } from 'axios';
import { IOutlet, IOutletSearchoptions } from '../Admin/Outlet/model/index';
import { IWarehouse, IWarehouseSearchoptions } from '../Admin/warehouse/model';
import { IDepartment, IDepartmentSearchoptions } from '../HR/Department/model';
import { IDesignation, IDesignationSearchoptions } from '../HR/Desigination/model';
import { IMember, IMemberSearchoptions } from '../BackOffice/NewMember/model';
import { IBrand, IBrandSearchoptions } from '../Warehouse/Brand/model';
import { IGsmCodeDetails, IItemSearchoptions, IitemMaster } from '../Warehouse/ItemMaster/model';
import { IItemInward, IItemInwardSearchoptions } from '../Warehouse/itemInward/model';
import { IAssignBatchNo, IAssignBatchNoSearchoptions } from '../Warehouse/assignBatchNo/model';
import { IAssignOfferAndDiscountSearchoptions, IAssignOfferDiscount } from '../CRM/AssignOfferDiscount/model';
// import { IIEmployee, IEmployeeSearchoptions } from '../HR/Employee/model';
import { IUnitOfMeasurement } from '../Warehouse/UnitOfMeasure/model';
import { IOutwardOutlet, IOutwardOutletSearchoptions } from '../Warehouse/OutwardtoOutlet/model';
import { IManagePriceChange } from '../CRM/PriceChangeUpdate/model';
import { IOutletItemRequest, IOutletItemRequestSearchoptions } from '../Stores/outletItemRequest/model';
import { ITeamMaster, ITeamMasterSearchoptions } from '../Warehouse/teamMaster/model';
import { IItemConversion, IItemConversionSearchoptions } from '../Warehouse/itemConversion/model';
import { IOfferDisount, IOfferDisountSearchoptions } from '../CRM/ManageOfferOrDiscount/model';
import { ILedgerGroup, ILedgerGroupSearchoptions } from '../Accounts/LedgerGroup/model';
import { ILedgerMaster, ILedgerMasterSearchoptions } from '../Accounts/LedgerMaster/model';
import { IUserCreation, IUserCreationSearchoptions } from '../Admin/userCreation/model';
import { IOutletManager, IOutletManagerSearchoptions } from '../Admin/assignOutletManager/model';
import { IWareHouseManager, IWareHouseManagerSearchoptions } from '../Admin/assignWareHouseManager/model';
import { ISupplierMasterSearchoptions, IsupplierMaster } from '../Warehouse/SupplierMaster/model';
import { IsubCategory, IsubCategorySearchoptions } from '../Warehouse/SubCategory/model';
import { IShiftMaster, IShiftMasterSearchoptions } from '../BackOffice/shiftMaster/model';
import { ITerminalCreation, ITerminalSearchoptions } from '../BackOffice/terminalCreation/model';
import { IAssignBatchPacking, IAssignBatchPackingSearchoptions } from '../Warehouse/assignBatchPacking/model';
import { IPackingInward, IPackingInwardSearchoptions } from '../Warehouse/packingInward/model';
import { IPackingDistribution, IPackingDistributionSearchoptions } from '../Warehouse/packingDistribution/model';
import { IInwardOutlet } from '../Stores/InwardOulet/model';
import { IAssignPos, IAssignPosSearchoptions } from '../BackOffice/assignPOSStaff/model';
import { IConfirmPaymentPayload, IPOSFreeItemRequest, IPOSHoldDetails, IPaymentRequest, IPosSalesDetails, IPossalesMaster } from '../Admin/POS/model';
import { IRackAllotment, IRackAllotmentSearchoptions } from '../Stores/rackAllotment/model';
import { IMRPPrice, ISellingPrice, ISellingPriceUpdateSearchoptions } from '../Admin/sellingPriceUpdate/model';
import { ISupplierMaster } from '../common/commonStore/model';
import { IAssignProduct } from '../Admin/assignProduct/model';
import { IAssignRedemption, IAssignRedemptionSearchoptions } from '../CRM/AssignRedemption/model';
import { IManageLoyalty, IManageLoyaltySearchoptions } from '../CRM/ManageLoyalty/model';
import { IStockAnalysis } from '../Reports/StockAnalysis/model';
import { IAssignOrder, IAssignOrderSearchoptions } from '../Admin/AssignOrder/model';
import { IDespatchEntry, IDespatchEntrySearchoptions } from '../Stores/despatchEntry/model';
import { IOutletStockAnalysis } from '../Reports/OutetStockAnalysis/model';
import { ICourierPartner, ICourierPartnerSearchoptions } from '../Admin/CourierPartnerCreation/model';
import { IDamageEntryWarehouse, IDamageEntryWarehouseSearchoptions } from '../Warehouse/DamageEntryWarehouse/model';
import { IDamageEntryOutlet, IDamageEntryOutletSearchoptions } from '../Warehouse/DamageEntryOutlet/model';
import { ICourierEntry, ICourierEntrySearchoptions } from '../Stores/CourierEntry/model';
import { IAssignFreeItem } from '../CRM/AssignFreeItem/models';
import { IRetrunReplacement } from '../BackOffice/SalesReturnOrReplacement/model';
import { IMemberPointsStatementRequest } from '../Admin/MemberPointsStatement/model';
import { IInwardRegister } from '../Admin/InwardRegister/model';
import { IInvoice, IInvoiceProductRequest } from '../BackOffice/Invoice/model';
import { IReceipt } from '../Accounts/ReceiptEntry/model';
import { IIdentSearchoptions, IIndent } from '../Warehouse/Indent/model';
import { IQuoteSupplier } from '../Admin/QuoteFromSupplier/model';
import { IPurchaseBill } from '../Accounts/PurchaseBill/model';
import { IDirectInward } from '../Stores/directInwardOutlet/model';
import { IAssignBatchOutlet } from '../Stores/assignBatchOutlet/model';
import { ICounterOpeningSave } from '../BackOffice/CounterOpeningEntry/model';
import { ICounterClose } from '../BackOffice/ClosingCashHandover/model';
import { PartyLedgerSearch } from '../Admin/PartyLedger/model';
import { IPurchaseOrderSaveModal, IPurchaseOrderSearchOptions } from '../Warehouse/PurchaseOrder/modal';
import { ILoadLedger, IPaymentVoucherSave } from '../Accounts/PaymentVoucher/model';
import { IDamageTransfer } from '../Stores/damageTransfer/model';
import { IDamageEntryApproval } from '../Warehouse/damageEntryApproval/model';
import { IIEmployee } from '../HR/Employee/model';
import { log } from 'console';
import { ICategoryAssignMaster } from '../CRM/CategoryAssignOffers/model';
import { IStockAdjustmentModel } from '../Admin/stockadjustment/model';
import { ICombopackmaster } from '../Warehouse/ComboPack/model';


const responseBody = <T>(response: AxiosResponse<T>) => response.data;

var config = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': '*',
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  
  },
  timeout: 16000000
  
};

const axiosInstance = axios.create({
  //  baseURL: 'http://localhost:49882/api/',
   baseURL: 'https://api.varthagam.in/api/',

  timeout: 16000000,
  headers: {
    'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': '*',
    'Content-Type': 'application/json', 'Accept': 'application/json'
  }
});

axiosInstance.interceptors.response.use(
  async (response) => {
    if (process.env.NODE_ENV === "development") await sleep(1000);
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

const request = {
  get: <T>(url: string, id: 0) => axiosInstance.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) => axiosInstance.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axiosInstance.put<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axiosInstance.delete<T>(url).then(responseBody)
};

const outlets = {
  saveOutlet: async (outlet: IOutlet) => {
    return axiosInstance.post('Outlet/OutletSave', JSON.stringify(outlet), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getOutlets: async (outletSearchOptions: IOutletSearchoptions) => axiosInstance.post<any>('Outlet/GetOutlets', outletSearchOptions, config).then(responseBody),
  getOutletDetails: async (outletId) => {
    axiosInstance.get('outlet/OutletDetails', {
      params: {
        outletId: JSON.stringify(outletId)
      }
    }).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  updateOutletDetails: async (outlet: IOutlet) => {
    axiosInstance.put('Outlet/OutletUpdate', outlet).then(res => {

    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteOutlet: async (outletId) => {

    return axiosInstance.delete('Outlet/OutletDelete?OutletId=' + outletId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
}
const warehouse = {
  saveWarehouse: async (warehouse: IWarehouse) => {
    return axiosInstance.post('Warehouse/WarehouseRegistration', JSON.stringify(warehouse), config).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getWarehouse: async () => axiosInstance.get<any>('Warehouse/GetWarehouses', config).then(responseBody),
  saveLoosedConversion: async (loosedConversion) => {
    return axiosInstance.post('Warehouse/LooseConversionSave', JSON.stringify(loosedConversion), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loosedConversionDetailJSON: async (id) => axiosInstance.get<any>(`Warehouse/LooseConversionDetailsJson?wid=0&eid=${id ?? 0}`, config).then(responseBody),
  loosedConversionDelete: async (id) => axiosInstance.delete<any>(`Warehouse/LooseconversionDelete?wid=0&id=${id ?? 0}`, config).then(responseBody),
  loadDamageEntryApprovalDetails: async (id) => axiosInstance.get<any>(`Warehouse/DamageApproveddetailsJson?wid=0&eid=${id ?? 0}`, config).then(responseBody),
  getWarehouseDetails: async (warehouseId) => {
    axiosInstance.get('Warehouse/LoadWarehouse', {
      params: {
        warehouseId: JSON.stringify(warehouseId)
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  updateWarehouseDetails: async (warehouse: IWarehouse) => {
    axiosInstance.put<string>('Warehouse/UpdateWareHouse', warehouse).then(res => {

    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteWarehouseDetails: async (warehouseId) => {
    axiosInstance.delete('Warehouse/WarehouseDelete', warehouseId).then(res => {

    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getWarehouseSearch: async (SearchOptions: IWarehouseSearchoptions) => {

    return axiosInstance.post<any>('Warehouse/GetWarehouseRegistrationSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteWarehouse: async (warehouseId) => {

    return axiosInstance.delete('Warehouse/WarehouseDelete?WarehouseId=' + warehouseId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  rejectionConversionSave: async (rejectionConversion) => {
    return axiosInstance.post('Warehouse/RejectionconversionApprovalSave', JSON.stringify(rejectionConversion), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getRejectionItems: async () => axiosInstance.get<any>(`Warehouse/getrejectionitems`, config).then(responseBody),
  loadRejectionconversionjson: async () => axiosInstance.get<any>(`Warehouse/loadRejectionconversionjson`, config).then(responseBody),
  rejectionStockAsonJson: async (rejectionParams) => {
    return axiosInstance.post('Warehouse/rejectionStockAsonJson', JSON.stringify(rejectionParams), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

  damageOutletRejectionDetails: async (fromdate, todate) => {
    return axiosInstance.get(`DamageEntryOutlet/DamageOutletRejectionDetails?fromdate=${fromdate}&todate=${todate}`, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

}
const brand = {
  loadBrands: async () => axiosInstance.get<any>('Brand/LoadBrands', config).then(responseBody),


  saveBrand: async (brand: IBrand) => {
    return axiosInstance.post('Brand/BrandSave', JSON.stringify(brand), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getBrand: async () => axiosInstance.get<any>('Brand/GetBrands', config).then(responseBody).catch(err => {
    if (err.response) {

      console.log(err.response)
    } else if (err.request) {

      console.log(err.request)
    } else {
      // anything else 
    }
  }),
  getBrandDetails: async (brandId) => {
    axiosInstance.get('Brand/LoadBrands', {
      params: {
        brandId: JSON.stringify(brandId)
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  updateBrand: async (brand: IBrand) => {

    return axiosInstance.put('Brand/BrandUpdate?BrandId=' + brand.brandId, brand, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteBrand: async (brandId) => {

    return axiosInstance.delete('Brand/BrandDelete?BrandId=' + brandId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  BrandDetails: async (brandId: Number) => {

    return axiosInstance.get<any>('Brand/BrandDetails', {
      params: {
        brandId: brandId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getBrandSearch: async (SearchOptions: IBrandSearchoptions) => {

    return axiosInstance.post<any>('Brand/GetBrandSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getEntryNo: async (flag) => {
    return axiosInstance.get('Brand/GetEntryNo', {
      params: {
        flag: flag
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },


}
const commonMasters = {
  loadNotificationMasters: async (userid) => axiosInstance.get<any>(`UserCreation/Notify?userid=${userid}`, config).then(responseBody),
  closeNotification: async (nid) => axiosInstance.get<any>(`UserCreation/closenotify?notifyid=${nid}&nname=msg`, config).then(responseBody),
  loadTaxMastes: async () => axiosInstance.get<any>('TaxMaster/GetTaxMasters', config).then(responseBody),
  loadInwardItemMaster: async () => axiosInstance.get<any>('POS/GetPosItemMaster', config).then(responseBody),
  //loadSuppliers: async () => axiosInstance.get<any>('Supplier/LoadSuppliers', config).then(responseBody),
  loadSuppliers: async (flag) => {
    return axiosInstance.get<any>('Supplier/LoadSuppliers', {
      params: {
        flag: JSON.stringify(flag)
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadSubcategory: () => axiosInstance.get<any>('Subcategory/LoadSubCategories', config).then(responseBody),
  loadWarehouses: () => axiosInstance.get<any>('Warehouse/LoadWarehouse', config).then(responseBody),
  loadItemMaster: () => axiosInstance.get<any>('ItemMaster/GetMasterComobos', config).then(responseBody),
  loadItemMasterJSON: () => axiosInstance.post<any>('ItemMaster/GetMasterComobosjson', {}, config).then(responseBody),
  loadItemListJson: () => axiosInstance.post<any>('POS/GETItemListJson', {}, config).then(responseBody),
  loadOwnItemMaster: () => axiosInstance.get<any>('Machine_Master/Loadownitemmaster', config).then(responseBody),
  loadWarehouseStockJSON: (wId) => axiosInstance.get<any>(`Warehouse/LoadWarehousestockitems?wid=0&eid=0`, config).then(responseBody),
  loadOutlet: () => axiosInstance.get<any>('Outlet/LoadOutlet', config).then(responseBody),
  loadEmployee: () => axiosInstance.get<any>('Employee/LoadEmployees', config).then(responseBody),
  getEmployee: () => axiosInstance.get<any>('Employee/GetUserEmployee', config).then(responseBody),
  loaduseremployee: () => axiosInstance.get<any>('Employee/LoadEmployees', config).then(responseBody),
  //loadIteminward: () => axiosInstance.get<any>('AssignBatch/LoadInwards', config).then(responseBody),
  loadIteminward: async (loadOptions) => {
    return axiosInstance.post<any>('AssignBatch/LoadInwards', loadOptions, config).then(res => {
      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadInwardItems: async (loadOptions) => {
    return axiosInstance.post<any>('AssignBatch/LoadIntemsInward', loadOptions, config).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadIndentItemName: () => axiosInstance.get<any>('GeneralIndent/loadItemNameFilter', config).then(responseBody),
  loadIndentWarehouse: () => axiosInstance.get<any>('GeneralIndent/loadWareHouseName', config).then(responseBody),
  loadIndentOutlet: () => axiosInstance.get<any>('GeneralIndent/loadOutletName', config).then(responseBody),
  loadManageOfferOrDiscount: () => axiosInstance.get<any>('OfferMaster/LoadOffers', config).then(responseBody),
  loadManagePriceMrp: () => axiosInstance.get<any>('ManagePriceChange/ManagePriceMrp', config).then(responseBody),
  loadLedgerGroup: () => axiosInstance.get<any>('LedgerGroup/LoadLedgerGroups', config).then(responseBody),
  loadItemConversion: () => axiosInstance.get<any>('ItemConversion/LoadItemMasterConversions', config).then(responseBody),
  loadUnitOfMeasure: () => axiosInstance.get<any>('Unit/GetUnits', config).then(responseBody),
  loadPackingItem: async () => axiosInstance.get<any>('PackingDistribution/LoadItemPackingRequest', config).then(responseBody),
  loadBatchDetail: async () => axiosInstance.get<any>('OutwardToOutlet/GetLoadBatchDetails', config).then(responseBody),

  loadTeamDetail: async () => axiosInstance.get<any>('TeamMaster/GetTeamMasters', config).then(responseBody),

  loadBatchPackingDetail: async () => axiosInstance.get<any>('AssignBatchPacking/LoadAssignBatchPacking', config).then(responseBody),
  loadShiftDetail: async () => axiosInstance.get<any>('ShiftMaster/GetShiftMaster', config).then(responseBody),
  loadTerminalDetail: async () => axiosInstance.get<any>('Terminal/LoadTeminals', config).then(responseBody),
  loadRackBatchDetail: async () => axiosInstance.get<any>('RackAllotment/LoadRackBatches', config).then(responseBody),
  loadRackItemDetail: async () => axiosInstance.get<any>('RackAllotment/LoadRackItems', config).then(responseBody),
  loadManageLoyaltyDetail: async () => axiosInstance.get<any>('AssignRedemption/LoadManageLoyalty', config).then(responseBody),

  loadAssignOrderDetail: async () => axiosInstance.get<any>('OrderAssign/GetOnlineOrderNo', config).then(responseBody),
  //   loadAssignItemDetails: async (orderMasterid) => {
  //     
  //     return axiosInstance.get<any>('AssignDespatch/loadassignitemdetails?',{
  // params:{
  //   orderMasterid:orderMasterid
  // }
  //     }).then(res => {

  //       return res.data;

  //     }).catch(err => {

  //       if (err.response) {
  //         console.log(err.response)
  //       } else if (err.request) {
  //         console.log(err.request)
  //       } else {
  //         // anything else 
  //       }
  //     })
  //   },

  //loadAssignItemDetails: async () => axiosInstance.get<any>('OrderAssign/GetOrderItemDetails',config).then(responseBody),
  loadDespatchOrder: async () => axiosInstance.get<any>('AssignDespatch/loadassignOrder', config).then(responseBody),
  //loadDamageItem: async () => axiosInstance.get<any>('DamageEntryOutlet/loadDamageItem', config).then(responseBody),
  loadDamageItem: async (outletId) => {
    return axiosInstance.get('DamageEntryOutlet/loadDamageItem', {
      params: {
        outletId: outletId
      }
    }).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

  //loadDamageWarehouseItem: async () => axiosInstance.get<any>('DamageEntryWarehouse/loadDamageWarehouseItem', config).then(responseBody),
  loadDamageWarehouseItem: async (warehouseId) => {
    return axiosInstance.get('DamageEntryWarehouse/loadDamageWarehouseItem', {
      params: {
        warehouseId: warehouseId
      }
    }).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const machineMaster = {
  packingPlanSave: async (body) => {
    return axiosInstance.post('Machine_Master/PackingplanSave', JSON.stringify(body), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deletePackingPlan: async (Packingid) => {

    return axiosInstance.delete('Machine_Master/PackingPlanDelete?Packingid=' + Packingid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  machineMasterSave: async (body) => {
    return axiosInstance.post('Machine_Master/MachinemasterSave', JSON.stringify(body), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getPackingPlanList: async () => axiosInstance.get<any>('Machine_Master/LoadPackingDetails', config).then(responseBody),
  getMachineMasterList: async () => axiosInstance.get<any>('Machine_Master/LoadMachineDetails', config).then(responseBody),
}
const itemInward = {
  saveItemInward: async (inwardDetails: IItemInward) => {
    return axiosInstance.post('Inward/InwardhouseSave', JSON.stringify(inwardDetails), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getInwardMaster: async () => axiosInstance.get<any>('Inward/GetWarehouses', config).then(responseBody),
  getInwardDetails: async (inwardNo) => {
    return axiosInstance.get<any>('Inward/GetWarehouseDetails', {
      params: {
        inWardId: JSON.stringify(inwardNo)
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteInwardDetail: async (inwardDetailNo) => {

    return axiosInstance.delete('Inward/InwardDetailDelete?InwardDetailNo=' + inwardDetailNo, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteInwardMaster: async (inwardNo) => {

    return axiosInstance.delete('Inward/InwardhosueDelete?InwardNo=' + inwardNo, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getInwardSearch: async (SearchOptions: IItemInwardSearchoptions) => {

    return axiosInstance.post<any>('Inward/GetInWardSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getLedgers: async (inwardNo) => {
    return axiosInstance.get<any>('Inward/GetLedgers', {
      params: {
        inWardId: JSON.stringify(inwardNo)
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getPurchaseOrderDetails: async (supid) => {
    return axiosInstance.get<any>(`Inward/Getpurchaseorderdetailsjson?supid=${supid}`, config).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getBarcodeInward: async (itemid) => {
    return axiosInstance.get<any>(`Inward/GetBarcodeInward?itemid=${itemid}`, config).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const itemMaster = {
  importExportForm: async (mmenu) => {
    return axiosInstance.post<any>(`Import_Export/Import_Export_Itemmaster?id=${mmenu}`, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  importExportFormSave: async (mmenu) => {
    return axiosInstance.post<any>(`Import_Export/Import_Export_ItemmasterSave`, mmenu).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  saveItemImage: async (itemImage: FormData) => {

    axiosInstance.post('ItemMaster/ItemMasterImageSave', itemImage).then(res => {

      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },

  saveItemMaster: async (itemMaster: IitemMaster) => {

    return axiosInstance.post('ItemMaster/SaveItemMaster', itemMaster, config).then(res => {

      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  updateItemMaster: async (itemMaster: IitemMaster) => {

    return axiosInstance.post('ItemMaster/ItemMasterUpdate', itemMaster, config).then(res => {

      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },

  //(itemMaster:IitemMaster)=>request.post<any>('ItemMaster/SaveItemMaster',itemMaster),

  saveImageItemMaster: async (itemImage: FormData) => request.post<any>('ItemMaster/ItemMasterImageSave', itemImage),
  uploadItemImage: async (itemImage: FormData, itemId) => request.post<any>('ItemMaster/UpdateItemage?itemId=' + itemId, itemImage),
  getItemMasterDetails: async (gsmCode?: IGsmCodeDetails) => {
    return axiosInstance.post('ItemMaster/GetItemDetails', gsmCode, config).then(function (response) {
      return response.data;
    }
    ).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getItemMaster: async () => axiosInstance.get<any>('ItemMaster/GetItemMasters').then(responseBody),
  //getTaxMaster: async () => axiosInstance.get<any>('LedgerAccount/LoadLedgerAccount', config).then(responseBody),
  getTaxMaster: async (flag) => {
    return axiosInstance.get<any>('LedgerAccount/LoadLedgerAccount', {
      params: {
        flag: flag
      }
    }).then(function (response) {
      return response.data;
    }

    ).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

  getSupplierDetails: async (itemId: number) => {
    return axiosInstance.get<any>('ItemMaster/GetSupplierDetails?itemId=' + itemId, config).then(function (response) {
      return response.data;
    }

    ).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

  getItemDetails: async (itemId: Number) => {

    return axiosInstance.get<any>('ItemMaster/GetItemMasterDetails', {
      params: {
        itemId: itemId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getGsmCode: async (gsmCode: IGsmCodeDetails) => {
    return axiosInstance.post('ItemMaster/GetGsmCode', gsmCode, config).then(function (response) {

      return response.data;
    }

    ).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getSearchItemDetails: async (searchOption: IItemSearchoptions) => {

    return axiosInstance.post<any>('ItemMaster/GetSearchItemDetails', searchOption, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteItemMaster: async (itemId) => {

    return axiosInstance.delete('ItemMaster/ItemMasterDelete?itemId=' + itemId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
}
const supplierMasters = {
  saveSupplier: async (supplierMaster: IsupplierMaster) => {
    return axiosInstance.post('Supplier/SupplierSave', JSON.stringify(supplierMaster), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getSupplierMaster: async () => axiosInstance.get<any>('Supplier/GetSuppliers', config).then(responseBody).catch(err => {
    if (err.response) {

      console.log(err.response)
    } else if (err.request) {

      console.log(err.request)
    } else {
      // anything else 
    }
  }),
  getSupplierMasterDetails: async (supplierId) => {
    axiosInstance.get<any>('Supplier/GetSuppliers', {
      params: {
        supplierId: JSON.stringify(supplierId)
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  updateSupplierMasterDetails: async (supplierMaster: IsupplierMaster) => {
    axiosInstance.put('Supplier/SupplierUpdate?SupplierId=', supplierMaster).then(res => {

    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteSupplier: async (supplierId) => {
    return axiosInstance.delete('Supplier/SupplierDelete?SupplierId=' + supplierId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getSupplierSearch: async (SearchOptions: ISupplierMasterSearchoptions) => {

    return axiosInstance.post<any>('Supplier/GetSupplierSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  SupplierDetails: async (supplierId: Number) => {

    return axiosInstance.get<any>('Supplier/SupplierDetails', {
      params: {
        supplierId: supplierId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  updateSupplier: async (supplierMasters: ISupplierMaster) => {
    return axiosInstance.put('Supplier/SupplierUpdate?SupplierId=' + supplierMasters.supplierId, supplierMasters, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  importExportFormSave: async (SearchOptions) => {
    return axiosInstance.post<any>('Import_Export/SupplierMaster_ImportSave', SearchOptions, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const departmentMasters = {
  saveDepartment: async (departmentMaster: IDepartment) => {
    return axiosInstance.post('Department/DepartmentSave', JSON.stringify(departmentMaster), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getDepartment: async () => axiosInstance.get<any>('Department/GetDepartments', config).then(responseBody).catch(err => {
    if (err.response) {

      console.log(err.response)
    } else if (err.request) {

      console.log(err.request)
    } else {
      // anything else 
    }
  }),
  DepartmentDetails: async (departmentId: Number) => {

    return axiosInstance.get<any>('Department/DepartmentDetails', {
      params: {
        departmentId: departmentId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  updateDepartment: async (department: IDepartment) => {

    return axiosInstance.put('Department/DepartmentUpdate?DepartmentId=' + department.departmentId, department, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },

  deleteDepartment: async (departmentId) => {

    return axiosInstance.delete('Department/DepartmentDelete', {
      params: {
        departmentId: departmentId
      }
    }).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {

        console.log("Error", err.message);
      }

      console.log(err.config);
    })
  },
  getDepartmentSearch: async (SearchOptions: IDepartmentSearchoptions) => {

    return axiosInstance.post<any>('Department/GetDepartmentSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const designationMasters = {
  saveDesignation: async (designationMasters: IDesignation) => {

    return axiosInstance.post('Designation/DesignationSave', JSON.stringify(designationMasters), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getDesignation: async () => axiosInstance.get<any>('Designation/GetDesignations', config).then(responseBody).catch(err => {
    if (err.response) {

      console.log(err.response)
    } else if (err.request) {

      console.log(err.request)
    } else {
      // anything else 
    }
  }),
  updateDesignation: async (designation: IDesignation) => {

    return axiosInstance.put('Designation/DesignationUpdate?DesignationId=' + designation.designationId, designation, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  DesignationDetails: async (designationId: Number) => {

    return axiosInstance.get<any>('Designation/DesignationDetails', {
      params: {
        designationId: designationId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteDesignation: async (designationId) => {

    return axiosInstance.delete('Designation/DesignationDelete?DesignationId=' + designationId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getDesignationSearch: async (SearchOptions: IDesignationSearchoptions) => {

    return axiosInstance.post<any>('Designation/GetDesignationSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },



}
const newmemberMasters = {

  saveNewmember: async (newmemberMasters: IMember) => {
    return axiosInstance.post('Member/MemberSave', JSON.stringify(newmemberMasters), config).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  UpdateNewmember: async (newmemberMasters: IMember) => {
    console.log("inside UpdateNewmember ", newmemberMasters)
    return axiosInstance.put('Member/MemberUpdate', (newmemberMasters), config).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getNewmember: async () => axiosInstance.get<any>('Member/GetMemberDetails', config).then(responseBody),
  
  getNewmemberDetails: async (memberId) => {
    axiosInstance.get('Member/GetMemberDetails', {
      params: {
        memberId: JSON.stringify(memberId)
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  MemberDetails: async (memberId: Number) => {
   
    return axiosInstance.get<any>('Member/GetmemberDetailsnew', {
      params: {
        memberId: memberId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

  getMembershipPlan: async (id) => axiosInstance.get<any>(`Member/LoadMembershipPlan?id=${id ?? 0}`, config).then(responseBody),
  memberShipSave: (memberShip) => {
    return axiosInstance.post('Member/MembershipSave', JSON.stringify(memberShip), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  membershipEdit: async (memberShip) => {
    return axiosInstance.put('/Member/MembershipEdit', JSON.stringify(memberShip), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteMembershipPlan: async (memberplanId) => {

    return axiosInstance.delete('Member/MembershipDelete?memberId=' + memberplanId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  assignMembershipSave: (memberShip) => {
    return axiosInstance.post('Member/MembershipAssignSave', JSON.stringify(memberShip), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  assignMembershipEdit: async (memberShip) => {
   
    return axiosInstance.put('/Member/MembershipAssignEdit', JSON.stringify(memberShip), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteassignMembership: async (assignId) => {

    return axiosInstance.delete('Member/MembershipAssignDelete?memberId=' + assignId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getAssignMebership: async () => axiosInstance.get<any>(`Member/LoadMembershipassignPlan`, config).then(responseBody),
  getEntryNo: async (flag) => {
    return axiosInstance.get('Helper/GetBackOfficeModuleEntryNo', {
      params: {
        flag: flag
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  updateNewmemberDetails: async (newmemberMasters: IMember) => {
    axiosInstance.put('Member/MemberUpdate', newmemberMasters).then(res => {

    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteNewmember: async (memberId) => {

    return axiosInstance.delete('Member/MemberDelete?MemberId=' + memberId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  importExportFormSave: async (SearchOptions) => {
    return axiosInstance.post<any>('Import_Export/Member_ImportSave', SearchOptions, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getNewmemberSearch: async (SearchOptions: IMemberSearchoptions) => {

    return axiosInstance.post<any>('Member/GetMemberDetailSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  saveCustomerFeedBack: async (feedback) => {
    return axiosInstance.post<any>('Member/FeedbackSave', JSON.stringify(feedback), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getCustomerFeedBack: async (mode) => {
    return axiosInstance.get<any>('Member/Loadfeedback?mode=' + mode, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  // 
}
const outletItemRequest = {
  ///saveItemInward: async(inwardDetails:IItemInward)=>request.post<string>('Inward/InwardhouseSave',inwardDetails),
  saveOutletItemRequest: async (outletItemRequest: IOutletItemRequest) => {

    return axiosInstance.post<any>('OutletItemRequest/SaveOutletItemRequest', JSON.stringify(outletItemRequest), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getOutletItemRequest: async () => axiosInstance.get<any>('OutletItemRequest/GetOutletItemRequest', config).then(responseBody),
  getOutletItemRequestDetails: async (entryNo) => {
    return axiosInstance.get<any>('OutletItemRequest/GetOutletItemRequestDetails', {
      params: {
        outletItemId: entryNo
      }
    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getOutletItemRequestSearch: async (SearchOptions: IOutletItemRequestSearchoptions) => {

    return axiosInstance.post<any>('OutletItemRequest/GetOutletItemRequestSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteOutletItemRequestDetail: async (outletItemDetailId) => {

    return axiosInstance.delete('OutletItemRequest/OutletItemDetailDelete?OutletItemDetailId=' + outletItemDetailId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteOutletItemRequestMaster: async (outletItemId) => {

    return axiosInstance.delete('OutletItemRequest/OutletItemDelete?OutletItemId=' + outletItemId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
}
const assignBatchNo = {
  updateAssignBatchNo: async (assignBatchNo: IAssignBatchNo) => {

    return axiosInstance.post('AssignBatch/InwardUpdateQty', JSON.stringify(assignBatchNo), config).then(res => {

      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  saveAssignBatchNo: async (assignBatchNo: IAssignBatchNo) => {

    return axiosInstance.post('AssignBatch/AssignBatchSave', JSON.stringify(assignBatchNo), config).then(res => {

      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getAssignbatch: async () => axiosInstance.get<any>('AssignBatch/GetAssignBatchs', config).then(responseBody),
  getAssignBatchDetls: async (assinNo) => {

    return axiosInstance.get<any>('AssignBatch/GetAssignBatchDetails', {
      params: {
        assignNo: assinNo
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteAssignbatchetail: async (assignDetilsNo) => {

    return axiosInstance.delete('AssignBatch/AssignBatchDetailDelete?assignDetailNo=' + assignDetilsNo, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteAssignbatchMaster: async (assignNo) => {

    return axiosInstance.delete('AssignBatch/AssignBatchDelete?assignNo=' + assignNo, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getAssignbatchSearch: async (SearchOptions: IAssignBatchNoSearchoptions) => {

    return axiosInstance.post<any>('AssignBatch/GetAssignBatchSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  }
}
const offerDiscount = {
  saveOfferDiscounts: async (offerDiscount: IOfferDisount) => {
    return axiosInstance.post('OfferMaster/OfferMasterSave', JSON.stringify(offerDiscount), config

    ).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  editOfferDiscounts: async (offerDiscount) => {
    return axiosInstance.put('/OfferMaster/OffermasterUpdate', JSON.stringify(offerDiscount), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getOfferDiscounts: async () => axiosInstance.get<any>('OfferMaster/LoadOffers', config).then(responseBody),
  getOfferDiscountsDetails: async (offerId) => {
    return axiosInstance.get<any>('OfferMaster/Getofferlist', {
      params: {
        offerId: offerId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  updateOfferDiscountsDetails: async (offerDiscount: IDesignation) => {
    axiosInstance.put('', offerDiscount).then(res => {

    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteOfferDetail: async (offerDetailid) => {

    return axiosInstance.delete('OfferMaster/OffermasterDetailDelete?offerDetailid=' + offerDetailid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteOfferMaster: async (offerId) => {

    return axiosInstance.delete('OfferMaster/OffermasteDelete?offerid=' + offerId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getOfferDiscountSearch: async (SearchOptions: IOfferDisountSearchoptions) => {

    return axiosInstance.post<any>('OfferMaster/GetOfferMasterrSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const assignOfferDiscount = {
  saveAssignOfferDiscount: (assignOfferItem: IAssignOfferDiscount) => {

    return axiosInstance.post('AssignOfferItem/AssignOfferSave', JSON.stringify(assignOfferItem), config).then(res => {

      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  updateAssignOfferDiscount: async (assignOfferItem: IAssignOfferDiscount) => {
    return axiosInstance.put('AssignOfferItem/AssignOfferUpdate' ,JSON.stringify(assignOfferItem), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getAssignOfferDiscounts: async () => axiosInstance.get<any>('AssignOfferItem/GetAssignOffers', config).then(responseBody),
  getAssignOfferDiscountsDetails: async (offerid) => {
    return axiosInstance.get<any>('AssignOfferItem/GetAssignOfferDetails', {
      params: {
        assignofferId: JSON.stringify(offerid)
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getAssignOfferDiscountSearch: async (SearchOptions: IAssignOfferAndDiscountSearchoptions) => {

    return axiosInstance.post<any>('AssignOfferItem/GetAssignOfferItemSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteAssignOfferMaster: async (assignOfferId) => {

    return axiosInstance.delete('AssignOfferItem/AssignOfferDelete?assignOfferId=' + assignOfferId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteAssignOfferDetail: async (assignOffeDetailId) => {

    return axiosInstance.delete('AssignOfferItem/AssignOfferDetailDelete?assignOffeDetailId=' + assignOffeDetailId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },

}
const employee = {
  saveEmployee: async (employee: IIEmployee) => {
    return axiosInstance.post('Employee/EmployeeSave', employee, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  updateEmployee: async (employee: IIEmployee) => {
    return axiosInstance.put('Employee/EmployeeUpdate', employee, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  saveEmployeeImage: async (employeePhoto: FormData, empId) => request.post<any>('Employee/EmployeeImage?empId=' + empId, employeePhoto),

  loadEmployeeFamilyDetails: async () => axiosInstance.get<any>('Employee/LoadFamilydetails', config).then(responseBody),
  loadEmployeeEducation: async () => axiosInstance.get<any>('Employee/LoadEmployee_Education', config).then(responseBody),
  loadEmployeeExperience: async () => axiosInstance.get<any>('Employee/LoadEmployee_Experience', config).then(responseBody),
  loadEmployeeNominee: async () => axiosInstance.get<any>('Employee/LoadEmployee_Nominee', config).then(responseBody),
  loadEmployeeSalaryDetails: async () => axiosInstance.get<any>('Employee/LoadEmployee_Salary_Details', config).then(responseBody),
  loadEmployeeOfficial: async () => axiosInstance.get<any>('Employee/LoadEmployee_Official', config).then(responseBody),
  loadEmployeeContact: async () => axiosInstance.get<any>('Employee/LoadEmployee_Contact', config).then(responseBody),
  loadEmployeeMaster: async () => axiosInstance.get<any>('Employee/LoadEmployee_master', config).then(responseBody),

  getLoadEmployees: async () => axiosInstance.get<any>('Employee/LoadEmployees', config).then(responseBody),
  getEmployees: async () => axiosInstance.get<any>('Employee/GetEmployees', config).then(responseBody),
  getItemwiseStockPOS: async (body) => {
    let url = `Products/ItemwiseStock_Batch_currentstock?outid=${body?.outletId}&itemid=${body?.itemid}`
    return axiosInstance.post<any>(url, body, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getEntryNo: async (flag) => {
    return axiosInstance.get('Employee/GetEntryNo', {
      params: {
        flag: flag
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getEmployeeSearch: async (SearchOptions) => {

    return axiosInstance.post<any>('Employee/GetEmployeeSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteEmployee: async (empId) => {
    return axiosInstance.delete('Employee/EmployeeDelete?EmpId=' + empId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
}
const jobmaster = {
  hrEntryNo: async (flag) => axiosInstance.get<any>(`HREntry/GetEntryNumber?flag=${flag}`, config).then(responseBody),
  saveJobMaster: (category) => {
    return axiosInstance.post(`JobMaster/JobmasterSave`, JSON.stringify(category), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadJobMaster: async () => axiosInstance.get<any>('JobMaster/LoadJobmaster', config).then(responseBody),
  saveJobVacancy: (category) => {
    return axiosInstance.post(`JobMaster/JobVacencySave`, JSON.stringify(category), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadJobVacancy: async () => axiosInstance.get<any>('JobMaster/LoadJobVacency', config).then(responseBody),
  loadPendingVacanceyApp: async () => axiosInstance.get<any>('JobMaster/LoadJobVacencyApproval', config).then(responseBody),
  saveVacancyApproval: (category) => {
    return axiosInstance.post(`JobMaster/Vacencyapprovalsave`, JSON.stringify(category), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
}
const resumeBank = {
  saveResumeBank: (category) => {
    return axiosInstance.post(`ResumeBank/ResumeBankSave`, JSON.stringify(category), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  saveResumeImage: async (resumeid, resumeFormData: FormData, photoFormData: FormData) => request.post<any>('ResumeBank/ResumeBankImage?resumeid=' + resumeid, resumeFormData),

  // saveResumeImage: (resumeId, photoFormData, resumeFormData) => {
  //   let imgPayload = {
  //     resumeid: resumeId,
  //     photopath: photoFormData,
  //     resumefilepath: resumeFormData,
  //   }
  //   return axiosInstance.post(``, imgPayload, config)
  //     .then(res => {
  //       return res.data;
  //     }).catch(function (err) {
  //       if (err.response) {
  //         console.log(err.response)
  //       } else if (err.request) {
  //         console.log(err.request)
  //       } else {
  //         console.log("Error", err.message);
  //       }
  //       console.log(err.config);
  //     })
  // },
  saveResumeShorlisting: (resumeListings) => {
    return axiosInstance.post(`ResumeBank/ResumeBankshortlistSave`, JSON.stringify(resumeListings), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadResumeBank: async () => axiosInstance.get<any>('ResumeBank/loadResumeBank', config).then(responseBody),
  loadCandidatedetails: async () => axiosInstance.get<any>('ResumeBank/loadCandidatedetails', config).then(responseBody),
  loadcontactdetails: async () => axiosInstance.get<any>('ResumeBank/loadcontactdetails', config).then(responseBody),
  loadkeyskills: async () => axiosInstance.get<any>('ResumeBank/loadkeyskills', config).then(responseBody),
  loadlanguage: async () => axiosInstance.get<any>('ResumeBank/loadlanguage', config).then(responseBody),
  loadworkexperience: async () => axiosInstance.get<any>('ResumeBank/loadworkexperience', config).then(responseBody),
  loadResumeBankshortlist: async () => axiosInstance.get<any>('ResumeBank/loadResumeBankshortlist', config).then(responseBody),
}
const interviewMaster = {
  saveInterviewMaster: (category) => {
    return axiosInstance.post(`Interview/InterviewmasterSave`, JSON.stringify(category), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadInterviewMaster: async () => axiosInstance.get<any>('Interview/Loadinterviewmaster', config).then(responseBody),

  saveInterviewLetter: (category) => {
    return axiosInstance.post(`Interview/InterviewletterSave`, JSON.stringify(category), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadInterviewLetter: async () => axiosInstance.get<any>('Interview/LoadInterviewLetter', config).then(responseBody),
  saveInterviewProcessMaster: (interviewProcess) => {
    return axiosInstance.post(`Interview/InterviewProcessmastersave`, JSON.stringify(interviewProcess), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadInterviewProcessMaster: async () => axiosInstance.get<any>('Interview/LoadInterviewProcessmaster', config).then(responseBody),

  saveInterviewSelectionMaster: (interviewProcess) => {
    return axiosInstance.post(`Interview/InterviewProcessselectionsave`, JSON.stringify(interviewProcess), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadInterviewSelectionMaster: async () => axiosInstance.get<any>('Interview/LoadIntervieProcessSelection', config).then(responseBody),
  loadIntervieProcessSelectiondetails: async () => axiosInstance.get<any>('Interview/LoadIntervieProcessSelectiondetails', config).then(responseBody),

  saveSelectionApproval: (interviewProcess) => {
    return axiosInstance.post(`Interview/InterviewProcessselectionApprovalsave`, JSON.stringify(interviewProcess), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadSelectionApproval: async () => axiosInstance.get<any>('Interview/LoadIntervieProcessSelectionapproveddetails', config).then(responseBody),
  saveAppoinmentProcess: (category) => {
    return axiosInstance.post(`Interview/AppoinmentProcessSave`, JSON.stringify(category), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadAppoinmentProcess: async () => axiosInstance.get<any>('Interview/LoadAppointmentProcess', config).then(responseBody),
  saveOfferLetter: (offerletter) => {
    return axiosInstance.post(`Interview/OfferletterSave`, JSON.stringify(offerletter), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadOfferLetterMaster: async () => axiosInstance.get<any>('Interview/LoadOfferLetter', config).then(responseBody),
  loadAppoinmentJobDet: async () => axiosInstance.get<any>('Interview/LoadAppoinmentJobDet', config).then(responseBody),
  loadAppoinmentOtherDoc: async () => axiosInstance.get<any>('Interview/LoadAppoinmentOtherDoc', config).then(responseBody),
  loadAppointmentSalaryDet: async () => axiosInstance.get<any>('Interview/LoadAppointmentSalaryDet', config).then(responseBody),
}
const employeepromotion = {
  saveEmployeePromotion: (employeePromotion) => {
    return axiosInstance.post('EmpPromotion/EmpPromotionSave', JSON.stringify(employeePromotion), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  loadEmpPromotion: async () => axiosInstance.get<any>('EmpPromotion/LoadEmpPromotion', config).then(responseBody),
  loadEmployeePromotionDet: async () => axiosInstance.get<any>('EmpPromotion/LoadEmployeePromotionDet', config).then(responseBody),
  loadEmployeePromoSalDet: async () => axiosInstance.get<any>('EmpPromotion/LoadEmployeePromoSalDet', config).then(responseBody),
  savePromotionApproval: (employeePromotion) => {
    return axiosInstance.post('EmpPromotion/PromotionApprovalSave', JSON.stringify(employeePromotion), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  loadPromotionApprovalList: async () => axiosInstance.get<any>('EmpPromotion/LoadPromotionApproval', config).then(responseBody),
  saveEmployeeIncrement: (employeePromotion) => {
    return axiosInstance.post('EmpPromotion/EmployeeIncrementSave', JSON.stringify(employeePromotion), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  loadEmployeeIncrement: async () => axiosInstance.get<any>('EmpPromotion/LoadEmployeeIncrement', config).then(responseBody),
  loadEmployeeIncrementdet: async () => axiosInstance.get<any>('EmpPromotion/LoadEmployeeIncrementdet', config).then(responseBody),
  loadEmployeeIncrementSalDet: async () => axiosInstance.get<any>('EmpPromotion/LoadEmployeeIncrementSalDet', config).then(responseBody),
  saveIncrementApproval: (employeePromotion) => {
    return axiosInstance.post('EmpPromotion/IncrementApprovalSave', JSON.stringify(employeePromotion), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  loadIncrementApproval: async () => axiosInstance.get<any>('EmpPromotion/LoadIncrementApproval', config).then(responseBody),
  saveResignationMaster: (employeePromotion) => {
    return axiosInstance.post('EmpPromotion/ResignationMasterSave', JSON.stringify(employeePromotion), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  loadResignationMaster: async () => axiosInstance.get<any>('EmpPromotion/LoadResignationMaster', config).then(responseBody),
  saveResignationApproval: (employeePromotion) => {
    return axiosInstance.post('EmpPromotion/ResignationApprovalSave', JSON.stringify(employeePromotion), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  loadResignationApproval: async () => axiosInstance.get<any>('EmpPromotion/LoadResignationApproval', config).then(responseBody),
  loadResignationApprovalApproved: async () => axiosInstance.get<any>('EmpPromotion/LoadResignationApprovalApprovedlist', config).then(responseBody),
  saveRejoinMaster: (employeePromotion) => {
    return axiosInstance.post('EmpPromotion/EmployeeRejoingSave', JSON.stringify(employeePromotion), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },

}
const hrReportsstore = {
  getReportAttendence: async (month, year) => axiosInstance.get<any>(`ReportAttendance/GetReportAttendence?Month=${month}&Year=${year}`, config).then(responseBody),
  getReportBalance: async (month, year) => axiosInstance.get<any>(`ReportAttendance/GetReportBalance?month=${month}&myear=${year}`, config).then(responseBody),
  getReportWeeklyAttendence: async (sdate, edate) => axiosInstance.get<any>(`ReportAttendance/GetReportWeeklyAttendence?SDate=${sdate}&EDate=${edate}`, config).then(responseBody),
  getReportWeeklyBalance: async (sdate, edate) => axiosInstance.get<any>(`ReportAttendance/GetReportWeeklyBalance?SDate=${sdate}&EDate=${edate}`, config).then(responseBody),
  getReporDailyAttendence: async (sdate, edate, empId) => axiosInstance.get<any>(`ReportAttendance/GetReporDailyAttendence?SDate=${sdate}&EDate=${edate}&EmpID=${empId}`, config).then(responseBody),
  getReportAttendenceRegister: async (month, year) => axiosInstance.get<any>(`ReportAttendance/GetReportAttendenceRegister`, {
    params: {
      Month: month,
      Year: year,
    }
  }).then(responseBody),
  getReportAbsentAttendence: async (month, year, empId) => axiosInstance.get<any>(`ReportAttendance/GetReportAbsentAttendence`, {
    params: {
      Month: month,
      Year: year,
      EmpID: empId
    }
  }).then(responseBody),
  getReportAbsentBalance: async (month, year, empId) => axiosInstance.get<any>(`ReportAttendance/GetReportAbsentBalance`, {
    params: {
      Month: month,
      Year: year,
      EmpID: empId
    }
  }).then(responseBody),
  getReportTimeSheetRegister: async (sdate, edate, empId) => axiosInstance.get<any>(`ReportAttendance/GetReportTimeSheetRegister`, {
    params: {
      SDate: sdate,
      EDate: edate,
      EmpID: empId
    }
  }).then(responseBody),
  getReportOTTimeSheetRegister: async (sdate, edate, empId) => axiosInstance.get<any>(`ReportAttendance/GetReportOTTimeSheetRegister`, {
    params: {
      SDate: sdate,
      EDate: edate,
      EmpID: empId
    }
  }).then(responseBody),
  getReportSalaryRegister: async (month, year) => axiosInstance.get<any>(`ReportAttendance/GetReportSalaryRegister`, {
    params: {
      SDate: month,
      EDate: year,
    }
  }).then(responseBody),
  getReportStrengthRegister: async (sdate) => axiosInstance.get<any>(`ReportAttendance/GetReportStrengthRegister`, {
    params: {
      SDate: sdate,
    }
  }).then(responseBody),
  getReportEmpInformationRegister: async () => axiosInstance.get<any>(`ReportAttendance/GetReportEmpInformationRegister`, config).then(responseBody),
  getReportRelievedEmployee: async () => axiosInstance.get<any>(`ReportAttendance/GetReportRelievedEmployee`, config).then(responseBody),
  getReportRejoinedEmployee: async () => axiosInstance.get<any>(`ReportAttendance/GetReportRejoinedEmployee`, config).then(responseBody),
  // /api/ReportAttendance/
}
const payroll = {
  saveCatogery: (category) => {
    return axiosInstance.post(`Employee/Employeecategorysave?Categoryid=${category?.Categoryid}&Category=${category?.Category}`, JSON.stringify(category), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadCatergoryMaster: async () => axiosInstance.get<any>('Employee/LoadEmployeecategory', config).then(responseBody),
  saveIncentiveRecoverySave: (incentive) => {
    return axiosInstance.post(`Employee/Incentive_Recoverysave?IType=${incentive?.IType}&Category=${incentive?.Category}`, JSON.stringify(incentive), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadIncentiveRecoverMaster: async () => axiosInstance.get<any>('Employee/LoadIncentive_Recovery_Master', config).then(responseBody),
  saveDeductionMaster: (deduction) => {
    return axiosInstance.post(`Employee/EmployeeDeductionmastersave`, JSON.stringify(deduction), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadDeductionMaster: async () => axiosInstance.get<any>('Employee/LoadDeduction_master', config).then(responseBody),
  saveLeaveMaster: (leaveMaster) => {
    return axiosInstance.post(`Attendence/LeavesettingSave`, JSON.stringify(leaveMaster), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  getLeaveSettingJSON: async () => axiosInstance.get<any>('Attendence/Loadleavesettingjson', config).then(responseBody),
  saveManualAttendance: (leaveMaster) => {
    return axiosInstance.post(`Attendence/AttendenceSave`, JSON.stringify(leaveMaster), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  getManualAttendance: async () => axiosInstance.get<any>('Attendence/LoadAttendencejson', config).then(responseBody),
  loadManualAttendanceJSON: async () => axiosInstance.get<any>('Attendence/LoadAttendenceApprovaljson', config).then(responseBody),
  saveMDApprovalEntrySave: (leaveApprovalMaster) => {
    return axiosInstance.post(`Attendence/AttendenceapprovalSave`, JSON.stringify(leaveApprovalMaster), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  saveLoanAndAdvance: (loanAndAdvance) => {
    return axiosInstance.post(`Attendence/LoanandadvanceSave`, JSON.stringify(loanAndAdvance), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadLoanAndAdvance: async () => axiosInstance.get<any>('Attendence/Getloansandadvance', config).then(responseBody),
  saveLoanAdvanceApproval: (loanAndAdvanceApproval) => {
    return axiosInstance.post(`Attendence/LoansandadvanceapprovalSave`, JSON.stringify(loanAndAdvanceApproval), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadLoanandadvanceApproval: async () => axiosInstance.get<any>('Attendence/Getloansandadvanceapproval', config).then(responseBody),
  getAttendanceForSalary: async (body) => axiosInstance.get<any>(`Attendence/GetsalaryAttencencedetails?sdate=${body?.sdate}&enddate=${body?.enddate}`, config).then(responseBody),
  getLoadBalance: async () => axiosInstance.get<any>(`Attendence/Getloanbalance`, config).then(responseBody),
  saveAllowanceDeduction: (body, type) => {
    let url = ''
    if (type == 'Allowance') {
      url = 'Attendence/EmployeeAllowenceSave'
    } else {
      url = 'Attendence/EmployeeDeductionSave'
    }
    return axiosInstance.post(url, JSON.stringify(body), config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  saveSalaryGeneration: (body) => {
    return axiosInstance.post(`Attendence/Generatesalary`, body, config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  getElClBalance: async (body) => axiosInstance.get<any>(`Attendence/Getelclbalance?empid=${body?.empid}&month=${body?.month}`, config).then(responseBody),
  getSalaryGeneration: async (body) => axiosInstance.get<any>(`Attendence/Getsalarydetails?sdate=${body?.sdate}&enddate=${body?.enddate}`, config).then(responseBody),
  getAllowanceDetails: async (body) => axiosInstance.get<any>(`Attendence/GetAllowancedetails?salaryid=${body?.salaryid}&Empid=${body?.Empid}`, config).then(responseBody),
  getDeductionDetails: async (body) => axiosInstance.get<any>(`Attendence/Getdeductiondetails?salaryid=${body?.salaryid}&Empid=${body?.Empid}`, config).then(responseBody),
  saveLeaveRequest: (body) => {
    return axiosInstance.post(`Attendence/LeaveRecordsSave`, body, config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  saveLeaveRequestApproval: (body) => {
    return axiosInstance.post(`Attendence/LeaveRecordsapprovalSave`, body, config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadLeaveRecordsDetail: async () => axiosInstance.get<any>(`Attendence/LoadLeaveRecordsDetail`, config).then(responseBody),
  saveEmployeeBreakInOutMaster: (body) => {
    return axiosInstance.post(`Attendence/EmployeeBreakInOutMstSave`, body, config)
      .then(res => {
        return res.data;
      }).catch(function (err) {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadBreakInOutMaster: async () => axiosInstance.get<any>(`Attendence/LoadBreakInOutMaster`, config).then(responseBody),
  loadBreakInOutDetail: async () => axiosInstance.get<any>(`Attendence/LoadBreakInOutDetail`, config).then(responseBody),
}
const unitOfMeasurement = {
  saveUnitOfMeasurement: (unitOfMeasurement: IUnitOfMeasurement) => {

    return axiosInstance.post('Unit/UnitSave', JSON.stringify(unitOfMeasurement), config).then(res => {

      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getUnitOfMeasurement: async () => axiosInstance.get<any>('Unit/GetUnits', config).then(responseBody),
  getUnitOfMeasurementDetails: async (unitId) => {
    axiosInstance.get('Unit/GetUnits', {
      params: {
        unitId: JSON.stringify(unitId)
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  updateUnitOfMeasurement: async (unitOfMeasurement: IUnitOfMeasurement) => {
    return axiosInstance.put('Unit/UnitUpdate?UnitId=' + unitOfMeasurement.unitId, unitOfMeasurement, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteteUnitOfMeasurement: async (uniteId) => {
    return axiosInstance.delete('Unit/UnitDelete?UnitId=' + uniteId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getUnitSearch: async (SearchOptions: IBrandSearchoptions) => {

    return axiosInstance.post<any>('Unit/GetUnit', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  UnitDetails: async (unitId: Number) => {

    return axiosInstance.get<any>('Unit/UnitDetails', {
      params: {
        unitId: unitId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getEntryNo: async (flag) => {

    return axiosInstance.get('Helper/GetStoresModuleEntryNo', {

      params: {
        flag: flag
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

}
const stockAdjustmentWarehouseData = {
  saveStockAdjustmentWarehouse: (stockAdjustmentWarehouse:IStockAdjustmentModel) => {
 
    return axiosInstance.post('Warehouse/StockAdjwarehouseSave', JSON.stringify(stockAdjustmentWarehouse), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getStockAdjustmentWarehouse: async (wid,id) => axiosInstance.get<any>(`Warehouse/loadStockAdjwarehouse?wid=0&id=${id ?? 0}`, config).then(responseBody),
  deleteStockAdjustmentWarehouse: async (wid,id) => {

    return axiosInstance.delete(`Warehouse/StockAdjwarehouseDelete?wid=0&id=${id ?? 0}`,  config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
}
const stockAdjustmentOutletData = {
  saveStockAdjustmentOutlet: (stockAdjustmentOutlet:IStockAdjustmentModel) => {
    // debugger
    return axiosInstance.post('OutwardToOutlet/StockAdjoutletSave', JSON.stringify(stockAdjustmentOutlet), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getStockAdjustmentOutlet: async (wid,id) => axiosInstance.get<any>(`OutwardToOutlet/loadStockAdjoutlet?wid=0&id=${id ?? 0}`, config).then(responseBody),
  deleteStockAdjustmentOutlet: async (wid,id) => {

    return axiosInstance.delete(`OutwardToOutlet/StockAdjoutletDelete?wid=0&id=${id ?? 0}`,  config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
}
const outwardtoOutlet = {
  loadOutletItems: async () => axiosInstance.get<any>('Outlet/LoadOutlet', config).then(responseBody),
  //loadOutletItemRequest: async () => axiosInstance.get<any>('OutwardToOutlet/LoadOutletItemRequests', config).then(responseBody),
  loadBatchs: async (itemId, wid) => {
    return axiosInstance.get('OutwardToOutlet/GetLoadBatchDetails',
      {
        params: {
          itemId: itemId,
          wid: wid
        }
      }).then(res => {


        return res.data;
      }).catch(function (err) {
        if (err.response) {

          console.log(err.response)
        } else if (err.request) {

          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadWarehouseQty: async (itemRequest) => {
    return axiosInstance.post('OutwardToOutlet/GetWarehouseQty', itemRequest, config).then(res => {


      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getOutwardOutlets: async () => axiosInstance.get<any>('OutwardToOutlet/GetOutwardToOutlet', config).then(responseBody),
  
  loadOutletItemRequest: async (outletId, wid) => {
    return axiosInstance.get('OutwardToOutlet/LoadOutletItemRequests',
      {
        params: {
          outletId: outletId,
          wid: wid
        }
      }).then(res => {


        return res.data;
      }).catch(function (err) {
        if (err.response) {

          console.log(err.response)
        } else if (err.request) {

          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadOutletItemRequestnew: async (mode, outletId, wid, Reqno, itemid, Barcode) => {
    return axiosInstance.get('OutwardToOutlet/LoadOutletItemRequestsnew',
      {
        params: {
          mode: mode,
          outletId: outletId,
          wid: wid,
          Reqno: Reqno,
          itemid: itemid,
          Barcode: Barcode
        }
      }).then(res => {


        return res.data;
      }).catch(function (err) {
        if (err.response) {

          console.log(err.response)
        } else if (err.request) {

          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loadOutletItem: async (outletItemId) => {
    return axiosInstance.get('OutwardToOutlet/LoadOutletItems',
      {
        params: {
          outletItemId: outletItemId
        }
      }).then(res => {


        return res.data;
      }).catch(function (err) {
        if (err.response) {

          console.log(err.response)
        } else if (err.request) {

          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  saveOutwardOutlet: (outwardOutlet: IOutwardOutlet) => {
    return axiosInstance.post('OutwardToOutlet/OutwardToOutletSave', JSON.stringify(outwardOutlet), config).then(res => {


      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getOutwardOutletsDetls: async (outwardId) => {
    return axiosInstance.get<any>('OutwardToOutlet/GetOutwardOutletDetails', {
      params: {
        outwardId: JSON.stringify(outwardId)
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteOutwardOutletDetail: async (outwardDetailId) => {

    return axiosInstance.delete('OutwardToOutlet/OutwardToOutletDetailDelete?outwardDetailId=' + outwardDetailId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteOutwardOutletMaster: async (outwardId) => {

    return axiosInstance.delete('OutwardToOutlet/OutwardToOutletDelete?outwardId=' + outwardId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getOutwardOutletSearch: async (SearchOptions: IOutwardOutletSearchoptions) => {

    return axiosInstance.post<any>('OutwardToOutlet/GetOutwardToOutletSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getOutletDCprint: async (dcno) => {
    return axiosInstance.post('OutwardToOutlet/OutletDCprint?dcno=' + dcno,
    ).then(res => {
      console.log(res.data, "ress")
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
    })
  },
  getStockSummaryItemWise: (salesSummaryBody) => {
    return axiosInstance.post('OutwardToOutlet/StockAson', JSON.stringify(salesSummaryBody), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
    })
  },
  getStockStummaryWarehouse: (salesSummaryBody) => {
    return axiosInstance.post('Warehouse/GetRepwarehouStockSummery', JSON.stringify(salesSummaryBody), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
    })
  },
  getStockSummaryAsOnJson: (salesSummaryBody) => {
    return axiosInstance.post('Warehouse/Warehousestockasonjson', JSON.stringify(salesSummaryBody), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
    })
  },
}
const managePriceChange = {
  saveManagePriceChange: (managePriceChange: IManagePriceChange) => {

    return axiosInstance.post('ManagePriceChange/ManagePriceChangeSave', JSON.stringify(managePriceChange), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getManagePriceChanges: async () => axiosInstance.get<any>('ManagePriceChange/ManagePriceChangelist', config).then(responseBody),
  getManagePriceChangesDetails: async (itemid) => {
    return axiosInstance.get<any>('ManagePriceChange/ManagePriceHistory', {
      params: {
        itemid: JSON.stringify(itemid)
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

}
const teamMaster = {
  saveTeamMaster: (teamMaster: ITeamMaster) => {

    return axiosInstance.post('TeamMaster/TeamMasterSave', JSON.stringify(teamMaster), config).then(res => {
      return res.data;
    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getTeamMaster: async () => axiosInstance.get<any>('TeamMaster/GetTeamMasters', config).then(responseBody),
  getTeamMasterDetails: async (teamMasterId) => {
    return axiosInstance.get<any>('TeamMaster/GetTeamMasterDetails', {
      params: {
        teamMasterId: teamMasterId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteTeamMasterDetail: async (teamMasterDetailId) => {

    return axiosInstance.delete('TeamMaster/TeamMasterDetailDelete?teamMasterDetailId=' + teamMasterDetailId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteTeamMaster: async (teamMasterId) => {

    return axiosInstance.delete('TeamMaster/TeamMasterDelete?teamMasterId=' + teamMasterId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getTeamMasterSearch: async (SearchOptions: ITeamMasterSearchoptions) => {

    return axiosInstance.post<any>('TeamMaster/GetTeamMasterSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}

const categoryAssignMaster = {
  saveCategoryAssignMaster: (categoryAssignMaster: ICategoryAssignMaster) => {

    return axiosInstance.post('OfferMaster/OffercustMasterSave', JSON.stringify(categoryAssignMaster), config).then(res => {
      return res.data;
    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getTeamMaster: async () => axiosInstance.get<any>('TeamMaster/GetTeamMasters', config).then(responseBody),

  getCategoryAssignMasterDetails: async (offerid) => {
    return axiosInstance.get<any>('OfferMaster/Loadcustoffers', {
      params: {
        offerid: offerid
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteTeamMasterDetail: async (teamMasterDetailId) => {

    return axiosInstance.delete('TeamMaster/TeamMasterDetailDelete?teamMasterDetailId=' + teamMasterDetailId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },  
  deleteCategoryAssignMaster: async (offercustmid) => {

    return axiosInstance.delete('OfferMaster/OffercustMasterDelete?offerid=' + offercustmid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getTeamMasterSearch: async (SearchOptions: ITeamMasterSearchoptions) => {

    return axiosInstance.post<any>('TeamMaster/GetTeamMasterSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}

const itemConversion = {
  saveItemConversion: (itemConversion: IItemConversion) => {

    return axiosInstance.post('ItemConversion/ItemConversionSave', JSON.stringify(itemConversion), config).then(res => {
      return res.data;
    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getItemConversion: async () => axiosInstance.get<any>('ItemConversion/GetItemConversion', config).then(responseBody),
  getItemConversionDetails: async (itemConversionId) => {
    return axiosInstance.get<any>('ItemConversion/GetItemConversionDetails', {
      params: {
        itemConversionId: itemConversionId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteItemConversionDetail: async (itemConversionDetailId) => {

    return axiosInstance.delete('ItemConversion/ItemConversionDetailDelete?itemConversionDetailId=' + itemConversionDetailId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteItemConversionMaster: async (itemConversionId) => {

    return axiosInstance.delete('ItemConversion/ItemConversionDelete?itemConversionId=' + itemConversionId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getItemConversionSearch: async (SearchOptions: IItemConversionSearchoptions) => {

    return axiosInstance.post<any>('ItemConversion/GetItemConversionSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const ledgerGroup = {
  saveLedgerGroup: async (ledgerGroup: ILedgerGroup) => {

    return axiosInstance.post('LedgerGroup/LedgerGroupSave', ledgerGroup, config).then(res => {

      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getLedgerGroup: async () => axiosInstance.get<any>('LedgerGroup/GetLadgerGroups', config).then(responseBody),
  getLedgerGroupDetails: async (groupId) => {
    axiosInstance.get('LedgerGroup/GetLadgerGroups', {
      params: {
        groupId: JSON.stringify(groupId)
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  LedgerGroupDetails: async (groupId: Number) => {

    return axiosInstance.get<any>('LedgerGroup/LedgerGroupDetails', {
      params: {
        groupId: groupId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  updateLedgerGroup: async (ledgerGroup: ILedgerGroup) => {

    return axiosInstance.put('LedgerGroup/LedgerGroupUpdate?GroupId=' + ledgerGroup.groupId, ledgerGroup, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteLedgerGroup: async (groupId) => {

    return axiosInstance.delete('LedgerGroup/LedgerGroupDelete', {
      params: {
        groupId: groupId
      }
    }).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getLedgerGroupSearch: async (SearchOptions: ILedgerGroupSearchoptions) => {

    return axiosInstance.post<any>('LedgerGroup/GetLedgerGroupSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getEntryNo: async (flag) => {
    return axiosInstance.get('LedgerGroup/GetEntryNo', {
      params: {
        flag: flag
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const ledgerMaster = {
  saveLedgerMaster: (ledgerMaster: ILedgerMaster) => {

    return axiosInstance.post('LedgerAccount/LedgerAccountSave', JSON.stringify(ledgerMaster), config).then(res => {
      return res.data;
    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getLedgerMaster: async () => axiosInstance.get<any>('LedgerAccount/GetLedgerAccounts', config).then(responseBody),
  getLedgerMasterDetails: async (groupId) => {
    axiosInstance.get('LedgerAccount/GetLedgerAccounts', {
      params: {
        groupId: JSON.stringify(groupId)
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  updateLedgerMaster: async (ledgerMaster: ILedgerMaster) => {

    return axiosInstance.put('LedgerAccount/LedgerAccountUpdate?LedgerId=' + ledgerMaster.ledgerGroupId, ledgerMaster, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteLedgerMaster: async (ledgerId) => {

    return axiosInstance.delete('LedgerAccount/LedgerAccountDelete', {
      params: {
        ledgerId: ledgerId
      }
    }).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getLedgerMasterSearch: async (SearchOptions: ILedgerMasterSearchoptions) => {

    return axiosInstance.post<any>('LedgerAccount/GetLedgerAccountSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  LedgerMasterDetails: async (ledgerId: Number) => {

    return axiosInstance.get<any>('LedgerAccount/LedgerAccountDetails', {
      params: {
        ledgerId: ledgerId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  saveBillEntry: (billEntry) => {
    return axiosInstance.post('FinanceEntry/BillEntrySave', JSON.stringify(billEntry), config).then(res => {
      return res.data;
    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getBillEntryMaster: async () => axiosInstance.get<any>('FinanceEntry/GetBillEntryMaster', config).then(responseBody),
  getBillENtryDetails: async (bill_entry_st_id) => {
    return axiosInstance.get('FinanceEntry/GetBillENtryDetails', {
      params: {
        bill_entry_st_id: JSON.stringify(bill_entry_st_id)
      }
    }).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getBillENtryTaxDetails: async (bill_entry_st_id) => {
    return axiosInstance.get('FinanceEntry/GetBillENtryTaxDetails', {
      params: {
        bill_entry_st_id: JSON.stringify(bill_entry_st_id)
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  //

}
const userCreationMasters = {
  saveUserCreation: async (UserCreationMaster: IUserCreation) => {

    return axiosInstance.post<any>('UserCreation/UserCreationSave', JSON.stringify(UserCreationMaster), config).
      then(res => {
        return res.data;
      }).catch(err => {
        if (err.response) {

          console.log(err.response)
        } else if (err.request) {

          console.log(err.request)
        } else {
          // anything else 
        }
      })
  },
  updateUserCreation: async (UserCreationMaster: IUserCreation) => {
    return axiosInstance.put('/UserCreation/UserCreationEdit?UserId=' + UserCreationMaster.UserId, UserCreationMaster, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getUserCreation: async () => {

    return axiosInstance.get<any>('UserCreation/GetUserCreation', config)
      .then(res => {

        return res.data;

      }).catch(err => {
        if (err.response) {

          console.log(err.response)
        } else if (err.request) {

          console.log(err.request)
        } else {
          // anything else 
        }
      })
  },
  getUserCreationSearch: async (SearchOptions: IUserCreationSearchoptions) => {

    return axiosInstance.post<any>('UserCreation/GetUserCreationSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteUserCreation: async (userId) => {

    return axiosInstance.delete('UserCreation/UserCreationDelete?UserId=' + userId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  alertNotifications: async (body) => {
    return axiosInstance.post<any>('UserCreation/Aleartnotification', body, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadAssignNotificationRolls: async (body) => {
    return axiosInstance.post<any>('UserCreation/Aleart_Notification', body, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadForms: async () => {
    return axiosInstance.get<any>('UserCreation/Loadforms', config)
      .then(res => {
        return res.data;
      }).catch(err => {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          // anything else 
        }
      })
  },
  userrightsSave: async (body) => {
    return axiosInstance.post<any>('UserCreation/UserrightsSave', body, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  // /api/UserCreation/
  loadformsname: async (mmenu) => {
    return axiosInstance.get<any>('UserCreation/Loadformsname', {
      params: {
        mmenu: mmenu,
      }
    }).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

  loandUserrights: async (empid, modulename, status) => {
    let url = `UserCreation/loandUserrights`
    try {
      const res = await axiosInstance.get(url, {
        params: {
          empid: empid,
          modulename: modulename,
          status: status
        }
      })
      return res.data;
    } catch (error: any) {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log(error);
      }
    }
  },
  deleteUserrights: async (userrightsid) => {
    return axiosInstance.delete<any>(`UserCreation/DeleteUserrights?userrightsid=${userrightsid}`)
      .then(res => {
        return res.data;
      }).catch(err => {
        if (err.response) {
          console.log(err.response)
        } else if (err.request) {
          console.log(err.request)
        } else {
          // anything else 
        }
      })
  },
  getUserScreenRights: async (empid, formName) => {
    let url = `UserCreation/UserFormRights?empid=${empid}&formname=${formName}`
    try {
      const res = await axiosInstance.get(url, {
        params: {
          empid: empid,
          formName: formName,         
        }
      })
      return res.data;
    } catch (error: any) {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log(error);
      }
    }
  }, 
}
const outletManagerMasters = {
  saveOutletManager: async (OutletManagerMaster: IOutletManager) => {
    return axiosInstance.post('OutletManager/OutletManagerSave', JSON.stringify(OutletManagerMaster), config).
      then(res => {
        return res.data;
      }).catch(err => {
        if (err.response) {

          console.log(err.response)
        } else if (err.request) {

          console.log(err.request)
        } else {
          // anything else 
        }
      })
  },
  getOutletManager: async () => {

    return axiosInstance.get<any>('OutletManager/GetOutletManager', config)
      .then(res => {

        return res.data;

      }).catch(err => {
        if (err.response) {

          console.log(err.response)
        } else if (err.request) {

          console.log(err.request)
        } else {
          // anything else 
        }
      })
  },
  getOutletManagerSearch: async (SearchOptions: IOutletManagerSearchoptions) => {

    return axiosInstance.post<any>('OutletManager/GetOutletManagerSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteOutletManager: async (omId) => {

    return axiosInstance.delete('OutletManager/OutletManagerDelete?OMId=' + omId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
}
const wareHouseManagerMasters = {
  saveWaeHouseManager: async (WareHouseManagerMaster: IWareHouseManager) => {

    return axiosInstance.post('WareHouseManager/WareHouseManagerSave', JSON.stringify(WareHouseManagerMaster), config).
      then(res => {
        return res.data;
      }).catch(err => {
        if (err.response) {

          console.log(err.response)
        } else if (err.request) {

          console.log(err.request)
        } else {
          // anything else 
        }
      })
  },
  getWareHouseManager: async () => {

    return axiosInstance.get<any>('WareHouseManager/GetWareHouseManager', config)
      .then(res => {

        return res.data;

      }).catch(err => {
        if (err.response) {

          console.log(err.response)
        } else if (err.request) {

          console.log(err.request)
        } else {
          // anything else 
        }
      })
  },
  getWareHouseManagerSearch: async (SearchOptions: IWareHouseManagerSearchoptions) => {

    return axiosInstance.post<any>('WareHouseManager/GetWareHouseManagerSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteWareHouseManager: async (wmId) => {

    return axiosInstance.delete('WareHouseManager/WareHouseManagerDelete?WMId=' + wmId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
}
const subcategory = {
  saveCategory: async (subcategory: IsubCategory) => {
    return axiosInstance.post('Brand/CategorySave', JSON.stringify(subcategory), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getCategory: async () => axiosInstance.get<any>('Brand/LoadCategory', config).then(responseBody),
  deleteCategory: async (subCategoryId) => {
    return axiosInstance.delete('Brand/CategoryDelete?catId=' + subCategoryId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  saveSubCategory: async (subcategory: IsubCategory) => {
    return axiosInstance.post('Subcategory/SubcategorySave', JSON.stringify(subcategory), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  updateSubcategory: async (subcategory: IsubCategory) => {

    return axiosInstance.put('Subcategory/SubcategoryUpdate?SubCategoryId=' + subcategory.subCategoryId, subcategory, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  SubcategoryDetails: async (subCategoryId: Number) => {

    return axiosInstance.get<any>('Subcategory/SubcategoryDetails', {
      params: {
        subCategoryId: subCategoryId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteSubcategory: async (subCategoryId) => {

    return axiosInstance.delete('Subcategory/SubcategoryDelete?SubCategoryId=' + subCategoryId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getSubcategorySearch: async (SearchOptions: IsubCategorySearchoptions) => {

    return axiosInstance.post<any>('Subcategory/GetSubcategorySearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getSubcategory: async () => axiosInstance.get<any>('Subcategory/GetSubcategories', config).then(responseBody),
}
const packingDistribution = {
  savePackingDistribution: (packingDistribution: IPackingDistribution) => {

    return axiosInstance.post('PackingDistribution/PackingDistributionSave', JSON.stringify(packingDistribution), config).then(res => {

      return res.data;

    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getPackingDistribution: async () => axiosInstance.get<any>('PackingDistribution/GetPackingDistribution', config).then(responseBody),

  getPackingDistributionDetails: async (packingDistributionId) => {
    return axiosInstance.get<any>('/PackingDistribution/GetPackingDistributionDetails', {
      params: {
        packingDistributionId: packingDistributionId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  
  getEntryNo: async (flag) => {

    return axiosInstance.get('Helper/GetStoresModuleEntryNo', {

      params: {
        flag: flag
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  packingSizeMasterSave: async (printSizeMaster: any) => {
    return axiosInstance.post<any>('PackingDistribution/PackingSizeMasterSave', printSizeMaster, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getPackingSizeMaster: async () => {
    return axiosInstance.get<any>('PackingDistribution/LoadPackingSizeMaster', config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getPackingDistributionSearch: async (SearchOptions: IPackingDistributionSearchoptions) => {
    return axiosInstance.post<any>('PackingDistribution/GetPackingDistributionSearch', SearchOptions, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deletePackingDistributionDetail: async (packingDistributionDetailId) => {

    return axiosInstance.delete('PackingDistribution/PackingDistributionDetailDelete?packingDistributionDetailId=' + packingDistributionDetailId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deletePackingDistributionMaster: async (PackingDistributionId) => {

    return axiosInstance.delete('PackingDistribution/PackingDistributionDelete?PackingDistributionId=' + PackingDistributionId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  loadBatchs: async (itemId, wid) => {
    return axiosInstance.get('OutwardToOutlet/GetLoadBatchDetails',
      {
        params: {
          itemId: itemId,
        }
      }).then(res => {


        return res.data;
      }).catch(function (err) {
        if (err.response) {

          console.log(err.response)
        } else if (err.request) {

          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
}
const packingInward = {
  savePackingInward: (packingInward: IPackingInward) => {

    return axiosInstance.post('PackingInward/PackingInwardSave', JSON.stringify(packingInward), config).then(res => {

      return res.data;
    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getPackingInward: async () => axiosInstance.get<any>('PackingInward/GetPackingInward', config).then(responseBody),
  getPackingInwardDetails: async (packingInwardId) => {
    return axiosInstance.get<any>('PackingInward/GetPackingInwardDetails', {
      params: {
        packingInwardId: packingInwardId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getEntryNo: async (flag) => {

    return axiosInstance.get('Helper/GetStoresModuleEntryNo', {

      params: {
        flag: flag
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getPackingInwardSearch: async (SearchOptions: IPackingInwardSearchoptions) => {

    return axiosInstance.post<any>('PackingInward/GetPackingInwardSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deletePackingInwardDetail: async (packingInwardDetailId) => {

    return axiosInstance.delete('PackingInward/PackingInwardDetailDelete?PackingInwardDetailId=' + packingInwardDetailId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deletePackingInwardMaster: async (packingInwardId) => {

    return axiosInstance.delete('PackingInward/PackingInwardDelete?PackingInwardId=' + packingInwardId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
}
const assignBatchPacking = {
  saveAssignBatchPacking: (assignBatchPacking: IAssignBatchPacking) => {

    return axiosInstance.post('AssignBatchPacking/AssignBatchPackingSave', JSON.stringify(assignBatchPacking), config).then(res => {

      return res.data;
    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getAssignBatchPacking: async () => axiosInstance.get<any>('AssignBatchPacking/GetAssignBatchPacking', config).then(responseBody),
  getAssignBatchPackingDetails: async (packingAssignBatchid) => {
    return axiosInstance.get<any>('/AssignBatchPacking/GetAssignBatchPackingDetails', {
      params: {
        packingAssignBatchid: packingAssignBatchid
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getEntryNo: async (flag) => {

    return axiosInstance.get('Helper/GetStoresModuleEntryNo', {

      params: {
        flag: flag
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteAssignBatchPackingDetail: async (packingAssignBatchdid) => {

    return axiosInstance.delete('AssignBatchPacking/AssignBatchPackingDetailDelete?PackingAssignBatchdid=' + packingAssignBatchdid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteAssignBatchPackingMaster: async (packingAssignBatchid) => {

    return axiosInstance.delete('AssignBatchPacking/AssignBatchPackingDelete?packingAssignBatchid=' + packingAssignBatchid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getAssignBatchPackingSearch: async (SearchOptions: IAssignBatchPackingSearchoptions) => {

    return axiosInstance.post<any>('AssignBatchPacking/GetAssignBatchPackingSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const terminalCreation = {
  saveTerminalCreation: (terminalCreation: ITerminalCreation) => {
    return axiosInstance.post('Terminal/TerminalSave', JSON.stringify(terminalCreation), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getTerminal: async (SearchOptions: ITerminalSearchoptions) => {

    return axiosInstance.post<any>('Terminal/GetTerminalSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  //getTerminal: async (terminalSearchOptions: ITerminalSearchoptions) => axiosInstance.post<any>('Terminal/GetTerminalSearch', terminalSearchOptions, config).then(responseBody),
  getTerminalCreation: async () => axiosInstance.get<any>('Terminal/GetTerminals', config).then(responseBody),
  getTerminalCreationDetails: async (terminalId) => {
    axiosInstance.get('TerminalGetTerminals', {
      params: {
        terminalId: JSON.stringify(terminalId)
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  updateTerminalCreation: async (terminalCreation: ITerminalCreation) => {

    return axiosInstance.put('Terminal/TerminalUpdate?TerminalId=' + terminalCreation.terminalId, terminalCreation, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteTerminalCreation: async (terminalId) => {
    return axiosInstance.delete('Terminal/TerminalDelete?TerminalId=' + terminalId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  TerminalDetails: async (terminalId: Number) => {

    return axiosInstance.get<any>('Terminal/TerminalDetails', {
      params: {
        terminalId: terminalId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getEntryNo: async (flag) => {

    return axiosInstance.get('Helper/GetStoresModuleEntryNo', {

      params: {
        flag: flag
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

}
const shiftMaster = {
  saveShiftMaster: (shiftMaster: IShiftMaster) => {
    return axiosInstance.post('ShiftMaster/ShiftMasterSave', JSON.stringify(shiftMaster), config).then(res => {

      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  updateShiftMaster: async (shiftMaster: IShiftMaster) => {
    return axiosInstance.put('/ShiftMaster/ShiftMasterUpdate', shiftMaster, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getShiftMaster: async () => axiosInstance.get<any>('ShiftMaster/GetShiftMaster', config).then(responseBody),
  getShiftMasterDetails: async (shiftId) => {
    return axiosInstance.get<any>('/ShiftMaster/GetShiftMasterDetails', {
      params: {
        shiftId: shiftId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getEntryNo: async (flag) => {

    return axiosInstance.get('Helper/GetStoresModuleEntryNo', {

      params: {
        flag: flag
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteShiftMaster: async (shiftId) => {

    return axiosInstance.delete('ShiftMaster/ShiftMasterDelete?ShiftId=' + shiftId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteShiftDetail: async (shiftdetid) => {

    return axiosInstance.delete('ShiftMaster/ShiftDetailDelete?Shiftdetid=' + shiftdetid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getShiftMasterSearch: async (SearchOptions: IShiftMasterSearchoptions) => {

    return axiosInstance.post<any>('ShiftMaster/GetShiftMasterSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  }
}
const InwardOutlet = {

  getEntryNo: async (flag) => {

    return axiosInstance.get('Helper/GetStoresModuleEntryNo', {
      params: {
        flag: flag
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getLoadOutward: async (outletId) => {
    return axiosInstance.get<any>('InwardOutlet/LoadOutwardOutlet', {
      params: {
        outletId: outletId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

  getInwardOutlet: async () => axiosInstance.get<any>('InwardOutlet/GetInwardOutlets', config).then(responseBody),
  getInwardOutletDetails: async (InwardOutletId) => {

    return axiosInstance.get('InwardOutlet/GetInwardOutletDetails', {
      params: {
        InwardOutletId: InwardOutletId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getLoadOutwardDetails: async (outwardId) => {

    return axiosInstance.get('InwardOutlet/LoadOutwardOutletItems', {
      params: {
        outwardOutletId: outwardId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  saveInwardOutlet: (inwardOutlets: IInwardOutlet) => {
    return axiosInstance.post('InwardOutlet/InwardOutletSave', JSON.stringify(inwardOutlets), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getInwardOutletSearch: async (SearchOptions: IOutletItemRequestSearchoptions) => {

    return axiosInstance.post<any>('InwardOutlet/GeInwardOutletSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteInwardOutletDetail: async (inwardOutletDetailId) => {

    return axiosInstance.delete('InwardOutlet/InwardOutletDetailDelete?InwardOutletDetailId=' + inwardOutletDetailId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteInwardOutletMaster: async (inwardOutletId) => {

    return axiosInstance.delete('InwardOutlet/InwardOutletDelete?InwardOutletId=' + inwardOutletId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
}
const adminModule = {
  getEntryNo: async (flag) => {
    return axiosInstance.get('AdminEntryNo/GetAdminModuleEntryNo', {
      params: {
        flag: flag
      }
    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getBillNo: async (request) => {
    return axiosInstance.post('AdminEntryNo/GetPosBillNo', request, config).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const helper = {
  IsExistMobileNo: async (mobileNo) => {
    return axiosInstance.get('Helper/isExistMobileNo', {
      params: {
        mobileNo: mobileNo
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getWareHosueModuleEntryNo: async (flag) => {
    return axiosInstance.get('Helper/GetWarehouseModuleEntryNo', {
      params: {
        flag: flag
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const assignPos = {
  saveAssignPos: (assignPos: IAssignPos) => {
    return axiosInstance.post('AssignPos/AssignPosSave', JSON.stringify(assignPos), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getAssignPos: async () => axiosInstance.get<any>('AssignPos/GetAssignPos', config).then(responseBody),
  getAssignPosDetails: async (shiftId) => {
    return axiosInstance.get<any>('/AssignPos/GetAssignPos', {
      params: {
        shiftId: shiftId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getAssignPosSearch: async (SearchOptions: IAssignPosSearchoptions) => {

    return axiosInstance.post<any>('AssignPos/GetAssignPosSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteAssignPos: async (entryId) => {

    return axiosInstance.delete('AssignPos/AssignPosDelete?EntryId=' + entryId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
}
const posMaster = {
  loadPOSProductList: (outletid) => {
    return axiosInstance.get(`POS/GetItemwiseStockJson?outletid=${outletid}`, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  GETItemwiseStockAll: async (mode:any, itemid: any, barcode: any, outletid: any) => {
    return axiosInstance.get<any>('POS/GETItemwiseStockAll', {
      params: {
        mode: mode,
        itemid: itemid,
        barcode: barcode,
        outletid: outletid
      }
    }).then(res => {
      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  GETItemListJson: (outletid) => {
    return axiosInstance.post(`POS/GETItemListJson?outletid=${outletid}`, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },

  savePosMaster: (posMaster: IPossalesMaster) => {

    return axiosInstance.post('POS/PosSalesSave', JSON.stringify(posMaster), config).then(res => {
      return res.data;
    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {

        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  submitcounterOpeningEntry: async (counterOpeningsave: ICounterOpeningSave) => {
    return axiosInstance.post<any>('Terminal/CounterOpeningSave', counterOpeningsave, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  submintClosingCashHandOver: async (closingCashHandOver: ICounterClose) => {
    return axiosInstance.post<any>('Terminal/CounterClosingSave', closingCashHandOver, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getInvoiceList: async (body) => {
    let url = `POS/GetPOSSalesdaywise`;
    if (body) {
      url = `POS/GetPOSSalesdaywise?outletid=${body?.outletid}&fromdate=${body?.fromdate}&mobileno=${body?.mobileno}&billno=${body?.billno}`
    } 
    // if (body?.mobileno && body?.billno) {
    //   url = `POS/GetPOSSalesdaywise?outletid=${body?.outletid}&fromdate=${body?.fromdate}&mobileno=${body?.mobileno}&billno=${body?.billno}`
    // } 
    // else if (body?.mobile) {
    //   url = `POS/GetPOSSalesdaywise?outletid=${body?.outletid}&fromdate=${body?.fromdate}&mobileno=${body?.mobileno}`
    // }
    // else if (body?.outletid) {
    //   url = `POS/GetPOSSalesdaywise?outletid=${body?.outletid}&fromdate=${body?.fromdate}&mobileno=${body?.mobileno}`
    // }
    //  else if (body?.billno) {
    //   url = `POS/GetPOSSalesdaywise?outletid=${body?.outletid}&fromdate=${body?.fromdate}&billno=${body?.billno}`
    // } else if (body?.mobile && body?.fromdate  && body?.outletid ) {
    //   url = `POS/GetPOSSalesdaywise?outletid=${body?.outletid}&fromdate=${body?.fromdate}&billno=${body?.billno}`
    // } else {
    //   url = `POS/GetPOSSalesdaywise?outletid=${body?.outletid}&fromdate=${body?.fromdate}`
    // }
    return axiosInstance.post(url, body, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  loadCounterOpeningMaster: async (denominations) => {
    return axiosInstance.post('Terminal/LoadCounterOpeningMaster?Denomationid=' + denominations.denomationmid, denominations, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  loadInvoiceBatchNo: async (itemid, outletid) => {
    return axiosInstance.get<any>('AssignPos/GetInvoiceBatchNo', {
      params: {
        itemid: itemid,
        outletid: outletid
      }
    }).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadClosingHandoverMaster: async (denominations) => {
    return axiosInstance.post('Terminal/LoadCounterClosingMaster?Denomationid=' + denominations.denomationmid, denominations, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteCounterEntry: async (denominationmid) => {
    return axiosInstance.delete('Terminal/CounterOpeningDelete?Denomationid=' + denominationmid).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteClosingHandoverEntry: async (denominationmid) => {
    return axiosInstance.delete('Terminal/CounterClosingdelete?Denomationid=' + denominationmid).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getCounterEntryDetails: async (denominationid) => {
    return axiosInstance.post(`Terminal/LoadCounterOpeningDetails?Denomationid=` + denominationid, denominationid, config).then(res => {
      return res.data
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getClosingHandOverDetails: async (denominationid) => {
    return axiosInstance.post(`Terminal/LoadCounterClosingDetails?Denomationid=` + denominationid, denominationid, config).then(res => {
      return res.data
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getCounterEntryUpdateDetails: async (denomination) => {
    return axiosInstance.post(`Terminal/CounterOpeningUpdate?Denomationid=` + denomination.denominationid, denomination, config).then(res => {
      return res.data
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getClosingHandOverUpdateDetail: async (denomination) => {
    return axiosInstance.post(`Terminal/CounterClosingUpdate?Denomationid=` + denomination.denominationid, denomination, config).then(res => {
      return res.data
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  holdCarts: (posHoldDetails: IPOSHoldDetails) => {
    return axiosInstance.post('POS/PosSalesHoldSave', JSON.stringify(posHoldDetails), config).then(res => {
      return res.data;
    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getPosCounter: async () => axiosInstance.get<any>('POS/GetPosCounter', config).then(responseBody),
  getPosItemMaster: async () => axiosInstance.get<any>('POS/GetPosItemMaster', config).then(responseBody),
  getPosItemOffers: async (membertype) => axiosInstance.get<any>('POS/GETItemwiseOfferJson?membertype=' + membertype, config).then(responseBody),
  getPosFreeItems: async () => axiosInstance.get<any>('POS/GETItemwisefreeqtyJson', config).then(responseBody),
  getPosMaster: async () => axiosInstance.get<any>('ShiftMaster/GetShiftMaster', config).then(responseBody),
  getHoldPOS: async () => axiosInstance.get<any>('POS/GetHoldPOSMaster?holdid=0', config).then(responseBody),
  deleteHoldBill: async (holdId) => {
    return axiosInstance.delete('POS/DeleteHoldPOSDelete', {
      params: {
        holdId: holdId
      }
    }).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getHoldPOSMaster: async (holdid) => axiosInstance.get<any>(`POS/GetHoldPOSMaster?holdid=${holdid}`, config).then(responseBody),
  getHoldPOSDetails: async (holdid) => axiosInstance.get<any>(`POS/GetHoldPOSDetails?holdid=${holdid}`, config).then(responseBody),
  getHoldPOSBatchDetails: async (holdid) => axiosInstance.get<any>(`POS/GetHoldPOSBatchDetails?holdid=${holdid}`, config).then(responseBody),

  getItemDetails: async (product) => {
    return axiosInstance.post<any>('POS/GetPosItemMasterDetails', product, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getPOSCustomerDetails: async (mobileNo) => {
    return axiosInstance.get<any>('POS/GetPOSCustomerDetails', {
      params: {
        mobileNo: mobileNo
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getPosMasterDetails: async (shiftId) => {
    return axiosInstance.get<any>('/ShiftMaster/GetShiftMasterDetails', {
      params: {
        shiftId: shiftId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getProductPriceDetails: async (posItemDetails: IPosSalesDetails) => {
    return axiosInstance.post<any>('POS/GetPOSProductDetails', JSON.stringify(posItemDetails), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadItemBatchNo: async (itemid, outletid) => {

    return axiosInstance.get('AssignPos/GetBatchNo',
      {
        params: {
          itemid: itemid,
          counterid: outletid
        }
      }).then(res => {


        return res.data;

      }).catch(function (err) {

        if (err.response) {

          console.log(err.response)
        } else if (err.request) {

          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  ProceedPayment: (paymentRequest: IPaymentRequest) => {

    return axiosInstance.post('POS/InitiatePayment', JSON.stringify(paymentRequest), config).then(res => {
      return res.data;
    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {

        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  confirmPayment: (confirmPaymentPayload: IConfirmPaymentPayload) => {

    return axiosInstance.post('POS/CompletePayment', JSON.stringify(confirmPaymentPayload), config).then(res => {
      return res.data;
    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {

        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getFreeItem: (pOSFreeItemRequest: IPOSFreeItemRequest) => {
    return axiosInstance.post('POS/GetPOSFreeItemDetails', JSON.stringify(pOSFreeItemRequest), config).then(res => {
      return res.data;
    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {

        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getBillvalueOffer: (checkofferRequest: IPOSFreeItemRequest) => {

    return axiosInstance.post('POS/GetOSBillValueOffer', checkofferRequest, config).then(res => {
      return res.data;
    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {

        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getInvoiceDetails: (posid?: number) => {
    return axiosInstance.get<any>(`POS/posbillprint?posid=${posid}`, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getPosBatchStock: (body) => {
    return axiosInstance.get<any>(`AssignPos/PosBatchStock?batchno=${body?.batchno}&counterid=${body?.counterid}`, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  }
}
const rackAllotment = {
  saveRackAllotment: (rackAllotment: IRackAllotment) => {
    return axiosInstance.post('RackAllotment/RackAllotmentSave', JSON.stringify(rackAllotment), config).then(res => {

      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getRackAllotment: async () => axiosInstance.get<any>('RackAllotment/GetRackAllotments', config).then(responseBody),
  getRackAllotmentDetails: async (rackEntryNo) => {
    return axiosInstance.get<any>('/RackAllotment/GetRackAllotmentDetails', {
      params: {
        rackEntryNo: rackEntryNo
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }

    })

  },
  loadRackBatchDetail: async (itemId) => {
    return axiosInstance.get<any>('RackAllotment/LoadRackBatches', {
      params: {
        itemId: itemId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }

    })

  },
  getEntryNo: async (flag) => {

    return axiosInstance.get('Helper/GetStoresModuleEntryNo', {

      params: {
        flag: flag
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getRackBatchSearch: async (SearchOptions: IRackAllotmentSearchoptions) => {

    return axiosInstance.post<any>('RackAllotment/GetRackAllotmentSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteRackBatchDetail: async (rackEntryDetailNo) => {

    return axiosInstance.delete('RackAllotment/RackAllotmentDetailDelete?RackEntryDetailNo=' + rackEntryDetailNo, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteRackBatchMaster: async (rackEntryNo) => {

    return axiosInstance.delete('RackAllotment/RackAllotmentDelete?RackEntryNo=' + rackEntryNo, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
}
const sellingPriceUpdate = {

  getSellingPriceUpdate: async () => axiosInstance.get<any>('SellingPriceChange/LoadSellingPriceUpdate', config).then(responseBody),
  getMRPHistory: async (itemId, BatchNo) => {
    return axiosInstance.get<any>('/SellingPriceChange/LoadMRPHistory', {
      params: {
        itemId: itemId,
        BatchNo: BatchNo
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })

  },
  loadOutletItemsExport: (salesRegisterRequestExport) => {
    return axiosInstance.post('POS/PosSalesRegisterExport', JSON.stringify(salesRegisterRequestExport), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
    })
  },
  getSellingPriceMRPHistory: async (itemId, BatchNo, Ptype) => {
    return axiosInstance.get<any>('/SellingPriceChange/LoadSellingHistory', {
      params: {
        itemId: itemId,
        BatchNo: BatchNo,
        Ptype: Ptype
      }
    }).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getMRPPriceDetails: async (itemId) => {
    return axiosInstance.get<any>('SellingPriceChange/LoadMRPDetail', {
      params: {
        itemId: itemId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })

  },
  getSellingDetails: async (itemId, batchNo) => {
    return axiosInstance.get<any>('SellingPriceChange/LoadSellingDetail', {
      params: {
        itemId: itemId,
        BatchNo: batchNo
         }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })

  },
  getLastInwardHistory: async (itemId, batchNo) => {
    return axiosInstance.get<any>('/SellingPriceChange/LoadInwardHistory', {
      params: {
        itemId: itemId,
        batchno: batchNo
      }
    }).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  saveMRPDetails: async (sellingmrp: IMRPPrice) => {
    return axiosInstance.post<any>('/SellingPriceChange/SellingMRPUpdateSave', JSON.stringify(sellingmrp), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  saveSellingDetails: async (sellingDetail: ISellingPrice) => {
    return axiosInstance.post<any>('SellingPriceChange/SellingUpdateSave', JSON.stringify(sellingDetail), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getSellingPriceSearch: async (SearchOptions: ISellingPriceUpdateSearchoptions) => {

    return axiosInstance.post<any>('SellingPriceChange/GetSearchSellingPrice', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const assignProduct = {
  loadItemMaster: async () => axiosInstance.get<any>('POS/GetPosItemMaster', config).then(responseBody),
  saveAssignProduct: async (assignProduct: IAssignProduct) => {
    return axiosInstance.post<any>('AssignProduct/AssignProductSave', assignProduct, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)

      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  updateAssignProduct: async (assignProductId, assignProduct: IAssignProduct) => {
    return axiosInstance.put<any>('AssignProduct/AssignProductUpdate?assignProductId=' + assignProductId, assignProduct, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteAssignProduct: async (assignProductId) => {
    return axiosInstance.delete('AssignProduct/AssignProductDelete', {
      params: {
        assignProductId: assignProductId
      }
    }).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {

        console.log("Error", err.message);
      }

      console.log(err.config);
    })
  },
  LoadingAssignProduct: async () => {
    return axiosInstance.get('AssignProduct/LoadAssignProduct', config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {

        console.log("Error", err.message);
      }

      console.log(err.config);
    })
  },
  getAssignProductDetails: async (assignProductId) => {
    return axiosInstance.get('AssignProduct/GetAssignProductDetails', {
      params: {
        assignProductId: assignProductId
      }
    }).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {

        console.log("Error", err.message);
      }

      console.log(err.config);
    })
  },
  getAssignProducts: async (itemSearchOptions: IItemSearchoptions) => {
    return axiosInstance.post('AssignProduct/GetAssignProducts', itemSearchOptions, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {

        console.log("Error", err.message);
      }

      console.log(err.config);
    })
  },
  getAssignProduct: async (assignProductId) => {
    return axiosInstance.get('AssignProduct/GetAssignProduct?assignProductId=' + assignProductId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {

        console.log("Error", err.message);
      }

      console.log(err.config);
    })
  },
}
const assignRedemption = {
  saveAssignRedemption: async (assignRedemption: IAssignRedemption) => {

    return axiosInstance.post('AssignRedemption/AssignRedemptionSave', JSON.stringify(assignRedemption), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getAssignRedemption: async () => axiosInstance.get<any>('AssignRedemption/GetAssignRedemption', config).then(responseBody),
  getAssignRedemptiondetails: async (id) => {
    return axiosInstance.get<any>('AssignRedemption/GetAssignRedemptionDetails', {
      params: {
        assignRedemptionId: id
      }
    }).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getAssignRedemptionSearch: async (SearchOptions: IAssignRedemptionSearchoptions) => {

    return axiosInstance.post<any>('AssignRedemption/GetAssignRedemptionSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteAssignRedemption: async (assignRedemId) => {

    return axiosInstance.delete('/AssignRedemption/AssignRedemptionDelete', {
      params: {
        assignRedemptionId: assignRedemId
      }
    }).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {

        console.log("Error", err.message);
      }

      console.log(err.config);
    })
  },

}
const manageLoyalty = {
  saveManageLoyalty: (manageLoyalty: IManageLoyalty[]) => {

    return axiosInstance.post('ManageLoyalty/ManageLoyaltySave', JSON.stringify(manageLoyalty), config).then(res => {

      return res.data;
    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getManageLoyalty: async (id) => axiosInstance.get<any>('ManageLoyalty/GetManageLoyalty?id=' + id, config).then(responseBody),
  getManageLoyaltyDetails: async (manageLoyaltyId) => {
    return axiosInstance.get<any>('/ManageLoyalty/GetManageLoyaltyDetails', {
      params: {
        manageLoyaltyId: manageLoyaltyId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

  getManageLoyaltySearch: async (SearchOptions: IManageLoyaltySearchoptions) => {

    return axiosInstance.post<any>('ManageLoyalty/GetManageLoyaltySearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteManageLoyaltyDetail: async (manageLoyaltyDetailId) => {

    return axiosInstance.delete('ManageLoyalty/ManageLoyaltyDetailDelete?ManageLoyaltyDetailId=' + manageLoyaltyDetailId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteManageLoyalty: async (manageLoyaltyId) => {

    return axiosInstance.delete('ManageLoyalty/ManageLoyaltyDelete?ManageLoyaltyId=' + manageLoyaltyId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
}
const stockAnalysis = {
  //getStockAnalysis: async () => axiosInstance.get<any>('OutwardToOutlet/GetRepOutwardToOutletStock?', config).then(responseBody),
  importExportFormSave: async (mmenu) => {
    return axiosInstance.post<any>(`Import_Export/Outletstock_ImportSave`, mmenu).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getStockAnalysis: (stockAnalysis: IStockAnalysis) => {

    return axiosInstance.post('Warehouse/warehousmovementstock', JSON.stringify(stockAnalysis), config).then(res => {

      return res.data;
    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getStockAnalysisBatchwise: (stockAnalysis: IStockAnalysis) => {
    const configWithTimeout = {
      ...config,
      timeout: 1000000000000
    };
    return axiosInstance.post('AssignDespatch/OutletStockAnalysis_Batchnowise', JSON.stringify(stockAnalysis), configWithTimeout).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
}
const stockSummary = {
  getWarehouseStockSummary: (stockSummaryBody) => {
    return axiosInstance.post('Warehouse/GetRepwarehouStockSummery', JSON.stringify(stockSummaryBody), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
    })
  },
  getWarehouseStockSummaryBatchwise: (stockSummaryBody) => {
    return axiosInstance.post('Warehouse/warehousestockAnalysis', JSON.stringify(stockSummaryBody), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
    })
  },
  importExportFormSave: async (mmenu) => {
    return axiosInstance.post<any>(`Import_Export/Warehousestock_ImportSave`, mmenu).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const outletStockSummary = {
  getOutletStockSummary: (stockSummaryBody) => {
    return axiosInstance.post('OutwardToOutlet/GetRepOutwardToOutletStockSummery', JSON.stringify(stockSummaryBody), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
    })
  }
}
const salesSummaryItemWise = {
  getSaleSummaryItemWise: (salesSummaryBody) => {
    return axiosInstance.post('POS/Salesdaywisesummery', JSON.stringify(salesSummaryBody), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
    })
  }
}
const purchaseBillReg = {
  getPurchaseBillRegDetails: () => {
    return axiosInstance.get('PurchaseBill/LoadPurchaseBillregister', config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
    })
  },
 
  loadpurchaseitemsExport: (salesRegisterRequestExport) => {
    return axiosInstance.post('PurchaseBill/PurchaseBillRegisterExport', JSON.stringify(salesRegisterRequestExport), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
    })
  },
  loadOutletItems: async (salesRegisterRequest) => {
    return axiosInstance.post<any>('POS/PosSalesRegister', salesRegisterRequest).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadOutletItemsDetails: async (posid: any, flag) => axiosInstance.post<any>(`POS/PosSalesRegisterDetails?posid=${posid}&flag=${flag}`, config).then(responseBody),
}

const purchaseOrder = {
  getSupplierNameDetails: async () => axiosInstance.get<any>('PurchaseOrder/GetPurchaseOrderSupplierDetails', config).then(responseBody),
  getCurrentAddress: async (supid: number) => axiosInstance.get(`PurchaseOrder/GetPurchaseOrderDespatchDetails?supid=${supid}`, config).then(responseBody),
  getSupplierItemsList: async (supplierName: string) => axiosInstance.get(`PurchaseOrder/GetPurchaseOrderItemDetails?suppliername=${supplierName}`, config).then(responseBody),
  savePurchaseOrder: async (purchaseOrder: IPurchaseOrderSaveModal) => {
    return axiosInstance.post(`PurchaseOrder/PurchaseOrderSave`, JSON.stringify(purchaseOrder), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
    })
  },
  savePurchaseOrderDirect: async (purchaseOrder: IPurchaseOrderSaveModal) => {
    return axiosInstance.post(`PurchaseOrderDirect/PurchaseOrderDirectSave`, JSON.stringify(purchaseOrder), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
    })
  },
  savePurchaseOrderWorkOrder: async (purchaseOrder: IPurchaseOrderSaveModal) => {
    return axiosInstance.post(`PurchaseOrderDirect/WorkordermasterSave`, JSON.stringify(purchaseOrder), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
    })
  },
  getPurchaseOrders: async (purcOrdOptions: IPurchaseOrderSearchOptions) => axiosInstance.get(`PurchaseOrder/GetPurchaseOrders`, {
    params: {
      fromDate: purcOrdOptions?.fromDate,
      toDate: purcOrdOptions?.toDate,
      po_no: purcOrdOptions?.po_no,
      vendor: purcOrdOptions?.vendor
    }
  }).then(responseBody),
  getPurchseOrdersMasters: async (purcOrdOptions: IPurchaseOrderSearchOptions) => axiosInstance.get(`PurchaseOrderDirect/PurchaseOrderDirectMaster`, {
    params: {
      fromDate: purcOrdOptions?.fromDate,
      toDate: purcOrdOptions?.toDate,
      po_no: purcOrdOptions?.po_no,
      vendor: purcOrdOptions?.vendor
    }
  }).then(responseBody),
  getPurchaseWorkOrderMaster: async (purcOrdOptions: IPurchaseOrderSearchOptions) => axiosInstance.get(`PurchaseOrderDirect/GetWorkordermaster`, {
    params: {
      fromDate: purcOrdOptions?.fromDate,
      toDate: purcOrdOptions?.toDate,
      po_no: purcOrdOptions?.po_no,
      vendor: purcOrdOptions?.vendor
    }
  }).then(responseBody),
  getPurchaseOrderDetails: async (purchaseorderid: string) => axiosInstance.get(`PurchaseOrder/GetPurchaseOrderDetails?purchaseorderid=${purchaseorderid}`).then(responseBody),
  getPurchaseOrderDirectDetails: async (purchaseorderid: string) => axiosInstance.get(`PurchaseOrderDirect/GetPurchaseOrderDetails?purchaseorderid=${purchaseorderid}`).then(responseBody),
  getPurchaseWorderOrderDetails: async (purchaseorderid: string) => axiosInstance.get(`PurchaseOrderDirect/GetWorkorderDetails?purchaseorderid=${purchaseorderid}`).then(responseBody),
  deletePurchaseOrder: async (purchaseOrderId) => {
    return axiosInstance.delete('PurchaseOrder/PurchaseOrderDelete?purchaseorderid=' + purchaseOrderId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deletePurchaseOrderDirect: async (purchaseOrderId) => {
    return axiosInstance.delete('PurchaseOrderDirect/PurchaseOrderDelete?purchaseorderid=' + purchaseOrderId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  purchaseOrderPrint: async (purchaseorderid: string) => axiosInstance.get(`PurchaseOrder/PurchaseOrderPrint?pono=${purchaseorderid}`).then(responseBody),
  purchaseOrderDirectPrint: async (purchaseorderid: string) => axiosInstance.get(`PurchaseOrderDirect/PurchaseOrderPrint?pono=${purchaseorderid}`).then(responseBody),
  purchaseWprkOrderPrint: async (purchaseorderid: string) => axiosInstance.get(`PurchaseOrderDirect/Workorderprint?pono=${purchaseorderid}`).then(responseBody),
  purchaseorderTeamsandcondition: async () => axiosInstance.get(`PurchaseOrder/purchaseorderTeamsandcondition`).then(responseBody),
  purchaseOrderScheduleGet: async () => axiosInstance.get(`PurchaseOrder/PurchaseOrderSchedule`).then(responseBody),
  purchaseorderdirectTeamsandcondition: async () => axiosInstance.get(`PurchaseOrderDirect/purchaseorderdirectTeamsandcondition`).then(responseBody),
  purchaseorderWORKORDERTeamsandcondition: async () => axiosInstance.get(`PurchaseOrderDirect/purchaseorderWORKORDERTeamsandcondition`).then(responseBody),
}
const expiryReg = {
  getExpiryRegList: async (expiryRegBody) => {
    return axiosInstance.post(`OutwardToOutlet/expiryregister`, JSON.stringify(expiryRegBody), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
    })
  }
}
const AssignOrder = {
  saveAssignOrder: async (AssignOrder: IAssignOrder) => {

    return axiosInstance.post('OrderAssign/OrderAssignOrderSave', JSON.stringify(AssignOrder), config).then(res => {

      return res.data;

    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getAssignOrder: async () => axiosInstance.get<any>('OrderAssign/GetOrderAssignOrderMasters', config).then(responseBody),
  getAssignOrderDetails: async (orderassignid) => {
    return axiosInstance.get<any>('OrderAssign/GetOrderAssignOrderDetails', {
      params: {
        orderassignid: orderassignid
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getAssignOrderSearch: async (SearchOptions: IAssignOrderSearchoptions) => {

    return axiosInstance.post<any>('OrderAssign/GetOrderAssignSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteAssignOrderDetail: async (orderassigndid) => {

    return axiosInstance.delete('OrderAssign/OrderAssignDetailDelete?orderassigndid=' + orderassigndid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteAssignOrderMaster: async (orderassignid) => {

    return axiosInstance.delete('OrderAssign/OrderAssignDelete?orderassignid=' + orderassignid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },

  loadAssignOrderItem: async (orderMasterid) => {

    return axiosInstance.get('OrderAssign/GetOrderItemDetails?',
      {
        params: {
          orderMasterid: orderMasterid
        }
      }).then(res => {


        return res.data;

      }).catch(function (err) {

        if (err.response) {

          console.log(err.response)
        } else if (err.request) {

          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },



}
const DespatchEntry = {
  saveDespatchEntry: async (DespatchEntry: IDespatchEntry) => {

    return axiosInstance.post('AssignDespatch/AssigndespatchSave', JSON.stringify(DespatchEntry), config).then(res => {

      return res.data;

    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getDespatchEntry: async () => axiosInstance.get<any>('AssignDespatch/LoadAssigndespatchMaster', config).then(responseBody),
  getDespatchEntryDetails: async (orderdespatchid) => {
    return axiosInstance.get<any>('AssignDespatch/LoadAssigndespatchdetails', {
      params: {
        orderdespatchid: orderdespatchid
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getDespatchEntrySearch: async (SearchOptions: IDespatchEntrySearchoptions) => {
    return axiosInstance.post<any>('AssignDespatch/GetAssigndespatchSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteDespatchEntryDetail: async (orderdespatchdid) => {

    return axiosInstance.delete('AssignDespatch/AssigndespatchDetailDelete?orderdespatchdid=' + orderdespatchdid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteDespatchEntryMaster: async (orderdespatchid) => {

    return axiosInstance.delete('AssignDespatch/AssigndespatchDelete?orderdespatchid=' + orderdespatchid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  loaddespatchitemdetails: async (orderMasterid) => {

    return axiosInstance.get('AssignDespatch/loaddespatchitemdetails?',
      {
        params: {
          orderMasterid: orderMasterid
        }
      }).then(res => {


        return res.data;

      }).catch(function (err) {

        if (err.response) {

          console.log(err.response)
        } else if (err.request) {

          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  loaddespatchitemdetailsBatch: async (orderMasterid) => {

    return axiosInstance.get('AssignDespatch/loaddespatchitemdetailsBatch?',
      {
        params: {
          orderMasterid: orderMasterid
        }
      }).then(res => {


        return res.data;

      }).catch(function (err) {

        if (err.response) {

          console.log(err.response)
        } else if (err.request) {

          console.log(err.request)
        } else {
          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
}
const outletStockAnalysis = {
  //getStockAnalysis: async () => axiosInstance.get<any>('OutwardToOutlet/GetRepOutwardToOutletStock?', config).then(responseBody),

  getOutletStockAnalysis : (outletStockAnalysis: IOutletStockAnalysis) => {

    return axiosInstance.post('AssignDespatch/OutletStockAnalysis', JSON.stringify(outletStockAnalysis), config).then(res => {
      console.log(res, 'appiiidaatttta');

      return res.data;
       
      
    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  }
}
const CourierPartner = {
  saveCourierPartner: async (CourierPartner: ICourierPartner) => {

    return axiosInstance.post('CourierCompany/CourierCompanySave', JSON.stringify(CourierPartner), config).then(res => {

      return res.data;

    }).catch(function (err) {

      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  saveCourierImage: async (logo: FormData, courierid) => request.post<any>('CourierCompany/UpdateImage?courierid=' + courierid, logo),
  getCourierPartner: async () => axiosInstance.get<any>('CourierCompany/CourierCompanyMaster', config).then(responseBody),
  getCourierPartnerDetails: async (courierid) => {
    return axiosInstance.get<any>('CourierCompany/CourierCompanyDetails?', {
      params: {
        courierid: courierid
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getCourierPartnerSearch: async (SearchOptions: ICourierPartnerSearchoptions) => {
    return axiosInstance.post<any>('CourierCompany/GetCourierCompanySearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteCourierPartnerDetail: async (courierdid) => {

    return axiosInstance.delete('CourierCompany/CourierCompanyDetailDelete?Courierdid=' + courierdid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteCourierPartnerMaster: async (courierid) => {

    return axiosInstance.delete('CourierCompany/CourierCompanyDelete?courierid=' + courierid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
}
const damageEntryOutlet = {
  loadOutletItems: async () => axiosInstance.get<any>('Outlet/LoadOutlet', config).then(responseBody),
  saveDamageEntryOutlet: (damageEntryOutlet: IDamageEntryOutlet) => {

    return axiosInstance.post('DamageEntryOutlet/DamageEntryOutletSave', JSON.stringify(damageEntryOutlet), config).then(res => {
      return res.data;

    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  saveDamageTransferDetail: (damageEntryOutlet: IDamageTransfer) => {
    return axiosInstance.post('DamageEntryOutlet/DamageTransferEntryOutletSave', JSON.stringify(damageEntryOutlet), config).then(res => {
      return res.data;

    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  saveDamageOutletReturn: (damageOutletReturn: IDamageTransfer) => {
    return axiosInstance.post('DamageEntryOutlet/DamageOutletTransferSave', JSON.stringify(damageOutletReturn), config).then(res => {
      return res.data;

    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  saveDamageEntryApproval: (damageEntryOutlet: IDamageEntryApproval) => {
    return axiosInstance.post('Warehouse/DamageApprovalSave', JSON.stringify(damageEntryOutlet), config).then(res => {
      return res.data;

    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  loadDamageTransferDetail: async (id) => axiosInstance.get<any>(`DamageEntryOutlet/DamageTransferEntryOutletJson?id=${id ?? 0}`, config).then(responseBody),
  loadDamageTransferPendingDetails: async (id, mode) => axiosInstance.get<any>(`Warehouse/Damageapproval_pendingjson?did=${id ?? 0}&mode=${mode ?? 0}`, config).then(responseBody),
  damageTransfterPrint: async (id) => axiosInstance.get<any>(`DamageEntryOutlet/DamageTransferprint?dcno=${id ?? 0}`, config).then(responseBody),
  loadDamageItemBatchNo: async (batchRequest) => {

    return axiosInstance.post<any>('DamageEntryOutlet/loadDamageItemBatchNo', batchRequest, config).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getDamageEntryOutlet: async () => axiosInstance.get<any>('DamageEntryOutlet/GetDamageEntryOutlet', config).then(responseBody),
  getDamageEntryOutletDetails: async (damagentryMaterid) => {
    return axiosInstance.get<any>('DamageEntryOutlet/GetDamageEntryOutletdetails?', {
      params: {
        damagentryMaterid: damagentryMaterid
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadDamageEntryOutletDetail: async (outletId) => axiosInstance.get<any>(`DamageEntryOutlet/DamageEntryOutletJson?outletid=${outletId}`, config).then(responseBody),
  deleteDamageEntryOutletDetail: async (damagedetailid) => {

    return axiosInstance.delete('DamageEntryOutlet/DamageOutletDetailDelete?damagedetailid=' + damagedetailid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteDamageEntryOutlet: async (damageid) => {

    return axiosInstance.delete('/DamageEntryOutlet/DamageOutletDelete?damageid=' + damageid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getDamageEntryOutletSearch: async (SearchOptions: IDamageEntryOutletSearchoptions) => {

    return axiosInstance.post<any>('DamageEntryOutlet/GetDamageEntryOutletSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

  //outlet return form
  loadDamageTransferOutletDetails: async () => axiosInstance.get<any>(`DamageEntryOutlet/LoadDamageTransferOutletDetails`, config).then(responseBody),
  
  LoadDamageTransfersuppliers: async () => axiosInstance.get<any>(`DamageEntryOutlet/LoadDamageTransfersuppliers`, config).then(responseBody),
  
  
  saveDamageOutletTransferMst: (damageEntryOutlet) => {
    return axiosInstance.post('DamageEntryOutlet/DamageOutletTransferMstSave', JSON.stringify(damageEntryOutlet), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  loadDamageTransferOutletDet: async () => axiosInstance.get<any>(`DamageEntryOutlet/LoadDamageTransferOutletDet`, config).then(responseBody),
  outletReturnPrint: async (id) => axiosInstance.get<any>(`DamageEntryOutlet/OutletReturnDetailsprint?dcno=${id ?? 0}`, config).then(responseBody),
}
const damageEntryWarehouse = {
  saveDamageEntryWarehouse: (damageEntryWarehouse: IDamageEntryWarehouse) => {
    return axiosInstance.post('DamageEntryWarehouse/DamageEntryWarehouseSave', JSON.stringify(damageEntryWarehouse), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  loadDamageWarehouseItemBatchNo: async (batchRequest) => {

    return axiosInstance.post<any>('DamageEntryWarehouse/loadDamageItemBatchNo', batchRequest, config).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getDamageEntryWarehouse: async () => axiosInstance.get<any>('DamageEntryWarehouse/GetDamageEntryWarehouse', config).then(responseBody),
  getDamageEntryWarehouseDetails: async (damagentryMaterid) => {
    return axiosInstance.get<any>('DamageEntryWarehouse/GetDamageEntryWarehousedetails?', {
      params: {
        damagentryMaterid: damagentryMaterid
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteDamageEntryWarehouseDetail: async (damagedetailid) => {

    return axiosInstance.delete('DamageEntryWarehouse/DamageWarehouseDetailDelete?damagedetailid=' + damagedetailid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteDamageEntryWarehouse: async (damagewareid) => {

    return axiosInstance.delete('DamageEntryWarehouse/DamageWarehouseDelete?damagewareid=' + damagewareid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getDamageEntryWarehouseSearch: async (SearchOptions: IDamageEntryWarehouseSearchoptions) => {

    return axiosInstance.post<any>('DamageEntryWarehouse/GetDamageEntryWarehouseSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const courierEntry = {
  loadDespatchNo: async () => axiosInstance.get<any>('CourierEntry/loadDespatchNo', config).then(responseBody),
  loadCourierName: async () => axiosInstance.get<any>('CourierEntry/loadCourierName', config).then(responseBody),
  saveCourierEntry: (courierEntry: ICourierEntry) => {

    return axiosInstance.post('CourierEntry/CourierEntrySave', JSON.stringify(courierEntry), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  loadDespatchDetail: async (despatchNo) => {

    return axiosInstance.get<any>('CourierEntry/loadDespatchDetail?', {
      params: {
        despatchNo: despatchNo
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteCourierEntry: async (courierEntryid) => {
    return axiosInstance.delete('CourierEntry/CourierEntryDelete?courierEntryid=' + courierEntryid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getCourierEntrySearch: async (SearchOptions: ICourierEntrySearchoptions) => {

    return axiosInstance.post<any>('CourierEntry/GetCourierEntrySearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getCourierEntry: async () => axiosInstance.get<any>('CourierEntry/GetCourierEntry', config).then(responseBody),

}
const salesRegister = {
  loadOutletItemsExport: (salesRegisterRequestExport) => {
    return axiosInstance.post('POS/PosSalesRegisterExport', JSON.stringify(salesRegisterRequestExport), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
    })
  },
  loadOutletItems: async (salesRegisterRequest) => {
    return axiosInstance.post<any>('POS/PosSalesRegister', salesRegisterRequest).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadOutletItemsDetails: async (posid: any, flag) => axiosInstance.post<any>(`POS/PosSalesRegisterDetails?posid=${posid}&flag=${flag}`, config).then(responseBody),
}

const inwardRegister = {
  loadOutletItems: async () => axiosInstance.get<any>('Outlet/LoadOutlet', config).then(responseBody),
}
const assignFreeItem = {
  getEntryNo: async (flag) => {
    return axiosInstance.get('AssignOfferItem/GetCRMModuleEntryNo', {
      params: {
        flag: flag
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadItems: async () => axiosInstance.get<any>('AssignOfferItem/GetFreeQuantityItemDetails', config).then(responseBody),
  loadOtherItmes: async () => axiosInstance.get<any>('AssignOfferItem/GetAssignFreeItems', config).then(responseBody),
  getAssignFreeItems: async () => axiosInstance.get<any>('AssignOfferItem/GetAssignFreeEntryDetails', config).then(responseBody),
  loadBatchNos: async (itemId) => {
    return axiosInstance.get('AssignOfferItem/GetAssignFreeBatchNoDetails', {
      params: {
        itemid: itemId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  SaveAssignFreeItem: async (assignFreeItem: IAssignFreeItem) => {
    return axiosInstance.post<any>('AssignOfferItem/AssignFreeEntrySave', assignFreeItem, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getAssignFreeItemDetail: async (assignFreeId) => {
    return axiosInstance.get('AssignOfferItem/GetAssignOfferItemDetail', {
      params: {
        assignFreeId: assignFreeId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getAssignFreeItemSearch: async (assignFreeItemSearch) => {
    return axiosInstance.post('AssignOfferItem/GetAssignFreeItemSearch', assignFreeItemSearch, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteAssignFreeItem: async (assignFreeId) => {

    return axiosInstance.delete('AssignOfferItem/AssignFreeOfferDelete?assignfreeentryid=' + assignFreeId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
}
const retrunReplace = {
  newItemRequest: async (newItemReq) => {
    return axiosInstance.post<any>('Products/NewitemRequestSave', newItemReq, config).then(res => {
      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadNewItemRequest: async () => axiosInstance.get<any>('Products/qryNewitemRequesitemdetails', config).then(responseBody),
  loadPosIds: async () => axiosInstance.get<any>('Products/LoadPosDetails', config).then(responseBody),
  loadInvoiceIds: async () => axiosInstance.get<any>('Products/LoadInvoces', config).then(responseBody),
  loadPackingDistributionItems: async () => axiosInstance.get<any>('Products/LoadPackingDistributionitems', config).then(responseBody),
  getInvoiceItems: async (invoiceId) => {
    return axiosInstance.get<any>('InvoiceEntry/getinvoiceitems?', {
      params: {
        salorderno: invoiceId
      }
    }).then(res => {
      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getProductionEntryDetails: async (body) => {
    let url = 'Products/'
    if (body?.mode) {
      url = `Products/LoadProductionDetails?mode=${body?.mode}&pid=${body?.pid}`
    }
    return axiosInstance.post<any>(url, body, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadPOSItems: async (posId) => {

    return axiosInstance.get<any>('Products/LoadSalesReturnItemDetails', {
      params: {
        posid: posId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadPOSItemBatch: async (posItemRequest) => {

    return axiosInstance.post<any>('Products/LoadSalesReturnBatchDetails', posItemRequest, config).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadReplacementItemBatch: async (itemId) => {

    return axiosInstance.get<any>('Products/LoadSalesReturnReplacementBatchDetails', {
      params: {
        itemid: itemId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  saveSalesReplacement: async (replacereturn: IRetrunReplacement) => {

    return axiosInstance.post<any>('Products/SaveItemReturnMaster', replacereturn, config).then(res => {

      return  res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
 
  getSalesRetrunDetails: async (sid) => {
    return axiosInstance.get<any>('Products/Replacement_Master_Details', {
      params: {
        id: Number(sid)
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

  getSalesRetrunDetailsSearch: async (posId) => {

    return axiosInstance.get<any>('Products/GetSalesRetrunDetailsSearch', {
      params: {
        posId: posId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  saveProductEntrySave: async (posItemRequest) => {

    return axiosInstance.post<any>("Products/ProductionSave", posItemRequest, config).then(res => {
      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadPackingPlanItems: async () => axiosInstance.get<any>('Products/LoadPackingPlanitems', config).then(responseBody),
  getProductionEntryList: async () => axiosInstance.get<any>('Products/LoadProductionMaster', config).then(responseBody),

}
const adminReports = {
  importExportFormSave: async (mmenu) => {
    return axiosInstance.post<any>(`Import_Export/EarnedPoints_ImportSave`, mmenu).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadMobileNumbers: async () => axiosInstance.get<any>('AdminReport/GetMobileNumbers', config).then(responseBody),
  getMinimumStock: async () => axiosInstance.get<any>('AdminReport/MinimumStockReport', config).then(responseBody),
  getMemberPointsStatement: async (memberPoinstRequest: IMemberPointsStatementRequest) => {

    return axiosInstance.post<any>('AdminReport/MemberPointsReport', memberPoinstRequest, config).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getInwardRegisterReport: async (inwardRegister: IInwardRegister) => {

    return axiosInstance.post<any>('Inward/GetInwardReports', inwardRegister, config).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const invoice = {
  getMembers: async () => axiosInstance.get<any>('InvoiceEntry/GetInvoiceMemberDetails', config).then(responseBody),
  getInvoiceProduts: async (invoiceId) => {

    return axiosInstance.get<any>('InvoiceEntry/GetInvoiceDetailsLists', {
      params: {
        invoicemasterid: invoiceId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getShippingAddress: async (memberId) => {

    return axiosInstance.get<any>('InvoiceEntry/GetShippingAddress', {
      params: {
        memberID: memberId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getInvoices: async () => axiosInstance.get<any>('InvoiceEntry/GetInvoices', config).then(responseBody),

  saveDirectInvoicesave: (invoiceMaster: IInvoice) => {
    return axiosInstance.post('InvoiceEntry/Saveinvoicemaster', JSON.stringify(invoiceMaster), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },

  saveInvoiceEntry: async (inviceDet) => {
    return axiosInstance.post<any>('InvoiceEntry/Saveinvoicemaster', inviceDet, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
      console.log(err, 'Error>>>')
    })
  },
  getProducts: async (invoiceProductRequest: IInvoiceProductRequest) => {
    return axiosInstance.post<any>('InvoiceEntry/GetProducts', invoiceProductRequest, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getInvoceSearch: async (outletId) => {

    return axiosInstance.get<any>('InvoiceEntry/GetInvoiceDetails', {
      params: {
        outletId: outletId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getInvoiceBillPrint: (posid?: number) => {
    return axiosInstance.get<any>(`InvoiceEntry/Invoiceprint?posid=${posid}`, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  salesOrderSave: async (inviceDet) => {
    return axiosInstance.post<any>('InvoiceEntry/SalesOrderSave', inviceDet, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
      console.log(err, 'Error>>>')
    })
  },
  loadSalesOrederDetails: async () => {
    return axiosInstance.get<any>('InvoiceEntry/LoadSalesOrederDetails', config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadSalesOrderLedger: async () => {
    return axiosInstance.get<any>('InvoiceEntry/LoadSalesOrderLedger', config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  // /api/InvoiceEntry/

  // ,
  // ,
}
const receipt = {
  saveReceipt: async (receipt: IReceipt) => {
    return axiosInstance.post<any>('Financial/ReceiptSave', receipt, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  saveJournal: async (Jounral) => {

    return axiosInstance.post<any>('Financial/JournalSave', Jounral, config).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  saveStockToWarehouse: async (Jounral) => {
    return axiosInstance.post<any>('Warehouse/StockToWarehouseSave', Jounral, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadWarehousePackingItems: async () => axiosInstance.get<any>('Warehouse/LoadPackingitems', config).then(responseBody),
  getReceipts: async () => axiosInstance.get<any>('Financial/GetReceipts', config).then(responseBody),

  getReceiptDetails: async (receiptId) => {

    return axiosInstance.get<any>('Financial/GetReceiptDetails', {
      params: {
        receiptId: receiptId
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getLedgers: async (ledgerRequest) => {

    return axiosInstance.post<any>('Financial/LoadLedgers', ledgerRequest, config).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getReceiptSearch: async (receiptSearchOptions) => {

    return axiosInstance.post<any>('Financial/GetReceiptSerach', receiptSearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getStockToWarehouseDetails: async (stockId) => {
    let url = 'Warehouse/GetStockToWarehouseDetails'
    if (stockId) {
      url = `Warehouse/GetStockToWarehouseDetails?Id=${stockId}`
    }
    return axiosInstance.get<any>(url, config).then(res => {
      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const indent = {
  saveIndent: async (indent: IIndent) => {

    return axiosInstance.post<any>('GeneralIndent/GeneralIndentSave', indent, config).then(res => {
      return res.data;

    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        //anything else
      }
    })
  },
  getEntryNo: async (flag) => {
    return axiosInstance.get('GeneralIndent/GetEntryNumber', {
      params: {
        flag: flag
      }
    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

  getIndent: async () => axiosInstance.get<any>('GeneralIndent/GetGeneralIndents?', config).then(responseBody),
  getIndentDetails: async (indentid) => {

    return axiosInstance.get<any>('GeneralIndent/GetGeneralIndentsDetails?', {
      params: {
        indentid: indentid
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteIndent: async (indentid) => {

    return axiosInstance.delete('GeneralIndent/GeneralIndentDelete?indentid=' + indentid, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getIndentSearch: async (SearchOptions: IIdentSearchoptions) => {

    return axiosInstance.post<any>('GeneralIndent/GetGeneralIndentSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const quoteSupplier = {
  saveQuoteSupplier: async (quoteFromSupplier: IQuoteSupplier) => {

    return axiosInstance.post<any>('QuoteSupplier/SaveQuoteFromSupplier', quoteFromSupplier, config).then(res => {
      return res.data;

    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        //anything else
      }
    })
  },
  getquoteFromSupplier: async () => axiosInstance.get<any>('QuoteSupplier/Quotefromsuppliers', config).then(responseBody),
  loadQuoteFromSupplierDetails: async () => axiosInstance.get<any>('QuoteSupplier/LoadQuoteFromSupplierDetails', config).then(responseBody),
  getquoteFromSupplierDetails: async (indent_id) => {

    return axiosInstance.get<any>('QuoteSupplier/QuoteFromSupplierItemDetails?', {
      params: {
        indent_id: indent_id
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadSupplierDetails: async (supplierId) => {

    return axiosInstance.get<any>('QuoteSupplier/GetQuoteSuppliers', {
      params: {
        supplierId: supplierId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getQuoteSearch: async (enteredBy) => {

    return axiosInstance.get<any>('QuoteSupplier/GetSearchQuote', {
      params: {
        enteredBy: enteredBy
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getquoteFromSupplierItemDetails: async (itemid, indent_id) => {

    return axiosInstance.get<any>('QuoteSupplier/QuoteFromSupplierItemSupplierDetails?', {
      params: {
        itemid: itemid,
        indent_id: indent_id
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const puchaseBill = {
  getInwardSupliers: async () => axiosInstance.get<any>('PurchaseBill/LoadInwardSuppliers', config).then(responseBody),
  getPurchaseBillMasterDetails: async () => axiosInstance.get<any>('PurchaseBill/GetPurchaseBillMasters', config).then(responseBody),
  getPurchseBillReturnJSON: async () => axiosInstance.get<any>('PurchaseBill/loadpurchasereturnbillsJson', config).then(responseBody),
  getInwards: async (supplierId) => {

    return axiosInstance.get<any>('PurchaseBill/LoadInwards', {
      params: {
        supplierId: supplierId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getPurchaseBillSearch: async (supplierId) => {

    return axiosInstance.get<any>('PurchaseBill/GetPurchaseBillMasterSearch', {
      params: {
        supplierId: supplierId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getInwardDetail: async (inwardRequest) => {

    return axiosInstance.post<any>('PurchaseBill/GetInwardDetails', inwardRequest, config).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  savePurchaseBill: async (puchaseBill: IPurchaseBill) => {

    return axiosInstance.post<any>('PurchaseBill/SavePurchaseBill', puchaseBill, config).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  savePurchaseReturn: async (puchaseReturn) => {
    return axiosInstance.post<any>('PurchaseBill/PurchasebillreturnSave', puchaseReturn, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  purchasereturndetailsJson: async (id) => {
    return axiosInstance.get<any>(`PurchaseBill/purchasereturndetailsJson?id=${id ?? 0}`, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  purchasereturndetailsprint: async (id) => {
    return axiosInstance.get<any>(`PurchaseBill/purchasereturndetailsprint?id=${id ?? 0}`, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  // /api/PurchaseBill/
  getPurchaseBillDetails: async (billId) => {

    return axiosInstance.get<any>('PurchaseBill/GetPurchaseBillDetails', {
      params: {
        billId: billId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const directInward = {
  saveDirectInward: async (inwardDetails: IDirectInward) => {
    return axiosInstance.post('DirectInward/DirectInwardSave', JSON.stringify(inwardDetails), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getDirectInwardMaster: async () => {
    try {
        const response = await axiosInstance.get('DirectInward/GetDirectInwards', config);
        return response.data; // Return processed response
    } catch (error) {
        console.error("Error fetching Direct Inward Master:", error);
        throw error; // Re-throw for higher-level handling
    }
},

getDirectInwardDetails: async (inwardNo) => {
  try {
      if (!inwardNo) throw new Error("Invalid inwardNo provided");
      const response = await axiosInstance.get('DirectInward/GetDirectInwardDetails', {
          params: {
              id: JSON.stringify(inwardNo),
              inWardId: JSON.stringify(inwardNo),
          },
      });
      return response.data; // Return processed response
  } catch (error) {
      console.error("Error fetching Direct Inward Details:", error);
      throw error; // Re-throw for higher-level handling
  }
},
getDirectInwardDetailsedit: async (inwardNo) => {
  try {
      if (!inwardNo) throw new Error("Invalid inwardNo provided");
      const response = await axiosInstance.get('DirectInward/GetDirectInwardDetailsedit', {
          params: {
              id: JSON.stringify(inwardNo),
              inWardId: JSON.stringify(inwardNo),
          },
      });
      return response.data; // Return processed response
  } catch (error) {
      console.error("Error fetching Direct Inward Details:", error);
      throw error; // Re-throw for higher-level handling
  }
},

  //getDirectInwardMaster: async () => axiosInstance.get<any>('DirectInward/GetDirectInwards', config).then(responseBody),
  //  getDirectInwardDetails: async (inwardNo) => {
  //   return axiosInstance.get<any>('DirectInward/GetDirectInwardDetails', {
  //     params: {
  //       id: JSON.stringify(inwardNo),
  //       inWardId: JSON.stringify(inwardNo)
  //     }
  //   }).then(res => {

  //     return res.data;
  //   }).catch(err => {

  //     if (err.response) {
  //       console.log(err.response)
  //     } else if (err.request) {
  //       console.log(err.request)
  //     } else {
  //       // anything else 
  //     }
  //   })
  // },
  deleteDirectInwardDetail: async (inwardDetailNo) => {

    return axiosInstance.delete('DirectInward/DirectInwardDetailDelete?InwardDetailNo=' + inwardDetailNo, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteDirectInwardMaster: async (inwardNo) => {

    return axiosInstance.delete('DirectInward/DirectInwardDelete?InwardNo=' + inwardNo, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getDirectInwardSearch: async (SearchOptions: IItemInwardSearchoptions) => {

    return axiosInstance.post<any>('DirectInward/GetDirectInWardSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getDirectLedgers: async (inwardNo) => {
    // DirectInward/GetdirectLedgers
    return axiosInstance.get<any>('DirectInward/GetdirectLedgers', {
      params: {
        inWardId: JSON.stringify(inwardNo)
      }
    }).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const assignBatchOutlet = {

  saveAssignBatchOutlet: async (assignBatchNo: IAssignBatchOutlet) => {

    return axiosInstance.post('AssignBatchOutlet/AssignBatchOutletSave', JSON.stringify(assignBatchNo), config).then(res => {

      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getAssignbatchOutlet: async () => axiosInstance.get<any>('AssignBatchOutlet/GetAssignBatchOutlet', config).then(responseBody),
  loadDirectInwards: async (supplierId) => {

    return axiosInstance.get<any>('AssignBatchOutlet/GetDirectInwards', {
      params: {
        supplierId: supplierId
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

  getAssignbatchOutletDetails: async (assinNo) => {

    return axiosInstance.get<any>('AssignBatchOutlet/GetAssignBatchOutletDetails', {
      params: {
        assignNo: assinNo
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  deleteAssignbatchOultetDetail: async (assignDetilsNo) => {

    return axiosInstance.delete('AssignBatchOutlet/AssignBatchOutletDetailDelete?assignDetailNo=' + assignDetilsNo, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  deleteAssignbatchOutletMaster: async (assignNo) => {

    return axiosInstance.delete('AssignBatchOutlet/AssignBatchOutletDelete?assignNo=' + assignNo, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getAssignbatchOutletSearch: async (SearchOptions: IAssignBatchNoSearchoptions) => {

    return axiosInstance.post<any>('AssignBatchOutlet/GetAssignBatchOutletSearch', SearchOptions, config).then(res => {

      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadDirectInwardItems: async (loadOptions) => {
    return axiosInstance.post<any>('AssignBatchOutlet/LoadIntemsDirectInward', loadOptions, config).then(res => {

      return res.data;
    }).catch(err => {

      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const purchaseApproval = {
  getindentMainDetails: async (body) => {
    return axiosInstance.get<any>(`PurchaseApproval/Getindent_main_details`, {
      params: {
        fromDate: body.fromDate,
        toDate: body.toDate,
        indentno: body.indentno,
        itemname: body.itemname
      }
    }).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getIndentDetails: async (indentId) => {
    return axiosInstance.get<any>(`PurchaseApproval/Getindent_details?indent_id=${indentId}`, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getIndentNoDetails: async (indentId) => {
    return axiosInstance.get<any>(`PurchaseApproval/Getindentno_details?indent_id=${indentId}`, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getSupplierNameDetails: async (indentId, itemId) => {
    return axiosInstance.get<any>(`PurchaseApproval/get_purchase_approval_item_details?indent_id=${indentId}&itemid=${itemId}`, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  submitPurchaseApproval: async (purchaseApproval) => {
    return axiosInstance.post<any>('PurchaseApproval/Purchase_Approval_Save', purchaseApproval, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  loadPurchaseApprvalList: async (body) => axiosInstance.get('PurchaseApproval/Getindentno_main_details', {
    params: {
      fromDate: body.fromDate,
      toDate: body.toDate,
      indentno: body.indentno,
      itemname: body.itemname
    }
  }).then(responseBody),
  deletePurchaseApproval: async (purchaseOrderId) => {
    return axiosInstance.delete('PurchaseApproval/delete_purchase_approval?indentid=' + purchaseOrderId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  }
}
const partyLedger = {
  getLedgerAccountSearch: async (SearchOptions: ILedgerGroupSearchoptions) => {
    return axiosInstance.post<any>('LedgerAccount/GetLedgerAccountSearch', SearchOptions, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getLedgerAccountStatement: async (PartyLedgerOptions: PartyLedgerSearch) => {
    return axiosInstance.post<any>('LedgerAccount/GetLedgerAccountStatmentSearch', PartyLedgerOptions, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getLedgerCashBook: async (PartyLedgerOptions) => axiosInstance.get<any>('LedgerAccount/GetLedgerCashBook', {
    params: {
      LedgerID: PartyLedgerOptions?.ledgerId,
      SDate: PartyLedgerOptions?.searchDatefrom,
      EDate: PartyLedgerOptions?.searchDateto
    }
  }).then(responseBody),
  getLedgerDayBook: async (PartyLedgerOptions) => axiosInstance.get<any>('LedgerAccount/GetLedgerDayBook', {
    params: {
      SDate: PartyLedgerOptions?.searchDatefrom,
      EDate: PartyLedgerOptions?.searchDateto
    }
  }).then(responseBody),
  getLedgerBankBook: async (PartyLedgerOptions) => axiosInstance.get<any>('LedgerAccount/GetLedgerBankBook', {
    params: {
      LedgerID: PartyLedgerOptions?.ledgerId,
      SDate: PartyLedgerOptions?.searchDatefrom,
      EDate: PartyLedgerOptions?.searchDateto
    }
  }).then(responseBody),
  getTrialBalance: async (PartyLedgerOptions) => axiosInstance.get<any>('LedgerAccount/GetTrialBalance', {
    params: {
      SDate: PartyLedgerOptions?.searchDatefrom,
      EDate: PartyLedgerOptions?.searchDateto
    }
  }).then(responseBody),
}
const paymentVoucher = {
  loadLedgers: async (loadLedger: ILoadLedger) => {
    return axiosInstance.post<any>('Financial/LoadLedgers', loadLedger, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  savePaymentVoucher: async (paymentVoucherSave: IPaymentVoucherSave) => {
    return axiosInstance.post<any>('Financial/PaymentSave', paymentVoucherSave, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  purchaseBill: async (supid) => axiosInstance.get<any>(`Financial/purchasebill?supid=${supid}`, config).then(responseBody),
  loadDebitNoteMaster: async () => axiosInstance.get<any>(`Financial/DebitCreditMaster`, config).then(responseBody),
  loadDebitNoteDetails: async (supid) => axiosInstance.get<any>(`Financial/DebitCreditDetails?drcrno=${supid}`, config).then(responseBody),
  loadPaymentBillDetail: async (paymentmasterid) => axiosInstance.get<any>(`Financial/GetPaymentbilldetails?paymentmasterid=${paymentmasterid}`, config).then(responseBody),
  voucherMasterGet: async (body) => {
    let url = 'Financial/Vouchermaster'
    if (body && body?.actype && body?.vocno) {
      url = `Financial/Voucherdetails?actype=${body?.actype}&vocno=${body?.vocno}`
    } else if (body && body?.actype) {
      url = `Financial/Vouchermaster?actype=${body?.actype}`
    }
    return axiosInstance.get<any>(url, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  }
}
const barcodePriceHistory = {
  getBarcodePriceHistoryList: async (barcodePostGet) => {
    return axiosInstance.post<any>('ItemMaster/ReportBarcodePrice', barcodePostGet, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const itemwiseMarginApi = {
  getItemwiseMarginList: async (itemwiseMargin) => {
    return axiosInstance.post<any>('Gsmreport/Reportsalesmargin', itemwiseMargin, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}

const combopack = {
  savecombopackMaster: (comboPack: ICombopackmaster) => {
    return axiosInstance.post('combopack/combopackSave', JSON.stringify(comboPack), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },

  getComboPackMasterList: async (mode: any, cmid: any) => {
    return axiosInstance.get<any>('combopack/LoadCombopackDetails', {
      params: {
        mode: mode,
        cmid: cmid,
      }
    }).then(res => {
      return res?.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

  deleteCombopackMaster: async (comboId) => {
    return axiosInstance.delete('combopack/Combopackdelete?comboId=' + comboId, config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },

}



const gstrAnnexure = {
  getGstrAnnexureList: async (body) => {
    return axiosInstance.get<any>(`Financial/Gstreport?mode=${body?.mode}&fdate=${body?.fdate}&tdate=${body?.tdate}`, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },

  getGstr2AnnexureList: async (body) => {
    return axiosInstance.get<any>(`Financial/GSTR2report?mode=${body?.mode}&fdate=${body?.fdate}&tdate=${body?.tdate}`, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        // anything else 
      }
    })
  }
}

const apiAgent = {
  outlets,
  brand,
  warehouse,
  commonMasters,
  itemInward,
  machineMaster,
  itemMaster,
  supplierMasters,
  departmentMasters,
  designationMasters,
  newmemberMasters,
  outletItemRequest,
  assignBatchNo,
  offerDiscount,
  assignOfferDiscount,
  employee,
  jobmaster,
  resumeBank,
  interviewMaster,
  employeepromotion,
  hrReportsstore,
  payroll,
  unitOfMeasurement,
  outwardtoOutlet,
  managePriceChange,
  teamMaster,
  itemConversion,
  stockAdjustmentWarehouseData,
  stockAdjustmentOutletData,
  ledgerGroup,
  ledgerMaster,
  userCreationMasters,
  outletManagerMasters,
  wareHouseManagerMasters,
  subcategory,
  packingDistribution,
  packingInward,
  assignBatchPacking,
  terminalCreation,
  shiftMaster,
  InwardOutlet,
  adminModule,
  helper,
  assignPos,
  posMaster,
  rackAllotment,
  sellingPriceUpdate,
  assignProduct,
  assignRedemption,
  manageLoyalty,
  stockAnalysis,
  AssignOrder,
  DespatchEntry,
  outletStockAnalysis,
  CourierPartner,
  damageEntryOutlet,
  damageEntryWarehouse,
  salesRegister,
  courierEntry,
  inwardRegister,
  assignFreeItem,
  retrunReplace,
  adminReports,
  invoice,
  receipt,
  indent,
  quoteSupplier,
  puchaseBill,
  directInward,
  assignBatchOutlet,
  purchaseApproval,
  partyLedger,
  stockSummary,
  outletStockSummary,
  salesSummaryItemWise,
  purchaseBillReg,
  purchaseOrder,
  expiryReg,
  paymentVoucher,
  barcodePriceHistory,
  itemwiseMarginApi,
  gstrAnnexure,
  categoryAssignMaster,
  combopack
};

export default apiAgent;