import { useState, useEffect, useRef, } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { Autocomplete, TextField } from '@mui/material';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import AutoComplete from '../../common/shared/autoComplete';
import _ from 'lodash';
import { removeDuplicates } from '../../common/shared/utils';
import { useParams } from 'react-router-dom';
import { ICategoryAssignMaster, ICategoryAssignMasters } from './model';
import categoryAssignValidation from './validation';
import memberStatemenValidation from '../../Admin/MemberPointsStatement/validation';
import { isNamedExportBindings } from 'typescript';

const CategoryAssignOffers = observer((): JSX.Element => {
    const { commonStore, teamMasterStore, offerDiscountStore, memberStore, categoryAssignMasterStore } = useStores();
    const { getTeamMasterDetails } = teamMasterStore;
    const { getOfferDiscounts } = offerDiscountStore;

    const [categoryAssignMaster, setCategoryAssignMaster] = useState<ICategoryAssignMaster>({});
    const [categoryAssignofMaster, setCategoryAssignofMaster] = useState<ICategoryAssignMasters>({});
    const [categoryAssignofMasters, setCategoryAssignofMasters] = useState<ICategoryAssignMasters[]>([]);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [errors, setErrors] = useState<any>({});
    const { loadEmployee, loadEmployees, } = commonStore;
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [offerList, setOfferList] = useState<any[]>([]);
    const [isFailureModal, setFailureModal] = useState(false);
    const [submitbtn, setSubmitbtn] = useState(false);
    const { offercustmid } = useParams<{ offercustmid: string }>();

    function handleChangeInput(event: any) {
        const { name, value } = event.target;

        setCategoryAssignMaster({ ...categoryAssignMaster, [name]: value })
    }

    function addCategoryOffer(e) {
        e.preventDefault();

        let error: any = {};
        error = categoryAssignValidation(categoryAssignMaster, categoryAssignofMaster);
        setErrors(error); 
            const updatelist = {
                ...categoryAssignofMaster, custtype: categoryAssignofMaster.custtype
            }
            setCategoryAssignofMasters(prevState => [...prevState, updatelist]);
            setSubmitbtn(true);
            console.log(categoryAssignMaster, 'categoryAssignMaster');  
            console.log(categoryAssignofMasters, 'categoryAssignofMasters');  
    }

    function handleRemove(index) {
        categoryAssignofMasters.splice(index, 1)
        setCategoryAssignofMasters(_.cloneDeep(categoryAssignofMasters))
    }

    const handleEmployeeValue = (name: any, event: object, val: any) => {
        if (val != null) {
            if (name === 'custtype') {
                setCategoryAssignofMaster({ ...categoryAssignofMaster, [name]: val.PlanName });
            }
            else {
                setCategoryAssignMaster({ ...categoryAssignofMaster, [name]: val.offerId, offerName: val.offerName });
            }
        } else {
            categoryAssignofMaster.custtype = '';
        }
    }

    async function deleteTeamMasterDetail(teamId: Number) {
        await teamMasterStore.deleteTeamMasterDetail(teamId)
    }

    async function saveTeamMaster(e) {
        if (offercustmid) {
            categoryAssignMaster.offercustDetails = categoryAssignofMasters
            const status = await categoryAssignMasterStore.saveCategoryAssignMaster(categoryAssignMaster);
            if (status === "Success") {
                setSuccessModal(true)
            }
            else {
                setFailureModal(true);
            }
        }
        else {
            categoryAssignMaster.offercustDetails = categoryAssignofMasters
            console.log(categoryAssignMaster,'categoryAssignMaster');
            
            const status = await categoryAssignMasterStore.saveCategoryAssignMaster(categoryAssignMaster)
            if (status === "Success") {
                setSuccessModal(true)
            }
            else {
                setFailureModal(true);
            }
        }


    }
    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        navigate('/Admin/CategoryAssignOffers/List');
    }

    const handleCancel = () => {
        navigate('/Admin/CategoryAssignOffers/List');
    }

    async function fetchPreLoadngData() {
        setLoading(false)
        await memberStore?.getMembershipPlan(0);
        const offdata = await offerDiscountStore.getOfferDiscounts();
        console.log(offdata, 'offdata');
        if (Array.isArray(offdata)) {
            setOfferList(offdata);
        }
        console.log(offerList, 'offerList');
    }

    async function getTeamMasterDet() {
        if (offercustmid) {
            const data: ICategoryAssignMaster[] =  await categoryAssignMasterStore?.getCategoryAssignMasterDetails(0)
            const formattedData = Array.from(data);
            console.log(JSON.stringify(formattedData, null, 2), 'data');
            const dataDet = await getTeamMasterDetails(offercustmid);
            console.log(dataDet, 'dataDet');
            setCategoryAssignofMasters(dataDet);
            console.log(categoryAssignofMasters, 'teamofMasters');

            const matchedUser = formattedData.find(team => Number(team.offerId) === Number(offercustmid));
            if (matchedUser) {
                const updatedDetails = {
                    ...categoryAssignMaster,
                    teamMasterId: matchedUser.offerId,
                    teamMasterName: matchedUser.offerName,
                };
                setCategoryAssignMaster(updatedDetails);
                console.log(categoryAssignMaster, 'teamMasterup');

            } else {
                console.error(`No user found with teamMasterId: ${offercustmid}`);
            }
        }
    }

    useEffect(() => {
        console.log("offerList", offerList);
    }, [offerList]);

    const isCurrenPage = useRef(true)

    useEffect(() => {
        if (isCurrenPage.current) {
            fetchPreLoadngData();
            getTeamMasterDet();
            isCurrenPage.current = false;
        }
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='container'>
                            <div className='vertical-space-20'></div>
                            <div className='outletInputField inputFormBox'>
                                <div className='hrBox' >
                                    <h3>Category - Assign Offers</h3>
                                </div>
                                <div className='inputBoxLists pd-b-0'>
                                    <div className='LgInputListsBox mr-left-0'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='inputBox'>
                                                    <label>Offer Name<span>*</span></label>
                                                    <AutoComplete options={removeDuplicates(offerList, 'offerId')} placeholder={'Offer Name...'}
                                                        // value={assignMemberSave?.planName}
                                                        emitOption={(option: any) => handleEmployeeValue('offerId', {}, option)}
                                                        getOptionLabel='offerName' />
                                                    {errors.offerName && <p style={{ color: 'red' }}>{errors.offerName}</p>}
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-md-8'>
                                                <div className='inputBox'>
                                                    <label>Customer Category  <span>*</span></label>
                                                    <AutoComplete options={removeDuplicates(memberStore?.memberPlanList, 'PlanName')} placeholder={'Customer Category...'}
                                                        emitOption={(option: any) => handleEmployeeValue('custtype', {}, option)}
                                                        clsName="full-width" getOptionLabel='PlanName' />
                                                    {errors.categoryassignName && <p style={{ color: 'red' }}>{errors.categoryassignName}</p>}

                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-sm-2'>
                                                <button className='dfBtn' onClick={addCategoryOffer}>Add</button>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Customer Category </th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {categoryAssignofMasters?.map((val, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{val.custtype}</td>
                                                                        <td><button style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }}
                                                                            type='submit' onClick={() => handleRemove(key)}>Remove</button></td>
                                                                    </tr>
                                                                )
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='btnBox'>

                                    <button className='secondaryBtn' onClick={handleCancel}>List</button>
                                    <button className='dfBtn' type='submit' onClick={saveTeamMaster} >Submit</button>
                                </div>
                            </div>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../gsmecom/images/checked.png')} />
                                        </div>

                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../gsmecom/images/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }
        </>
    );
});

export default CategoryAssignOffers;
