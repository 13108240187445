import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IOutletStockAnalysis } from './model';
import { Autocomplete, Pagination, Stack, TextField } from '@mui/material';
import useStores from '../../hooks';
import ProgressBar from '../../common/shared/progressbar';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { currentPageDatas, sortedData } from '../../common/shared/utils';
import AutoComplete from '../../common/shared/autoComplete';
import { Console, log } from 'console';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export interface ExcelSheetData {
  ttype?: string;
  outletId?: string ;
  vocno?: string ;
  vocdate?: string ;
  Memberid?: string ;
  membername?: string ;
  itemid?: string ;
  gsmcode?: string ;
  itemname?: string ;
  Hsncode?: string ;
  Category?: string ;
  subcategoryname?: string ;
  brandname?: string ;
  Unitname?: string ;
  barcode?: string ;
  batchno?: string ;
  rackNo?: string ;
  BrandType?: string ;
  OpenQty?: string ;
  ReceQty?: string ;
  Recerate?: string ;
  Recevalue?: string ;
  issqty?: string ;
  issrate?: string ;
  Amount?: string ;
  ClosingQty?: string ;
}

function OutletStockAnalysis() {
  const navigate = useNavigate();

  const { commonStore, outletStockAnalysisStore } = useStores();
  const { loadOutlet, loadOutlets, loadSubcategory, loadSubcategories, loadItemConversion, loadItemConversions } = commonStore;
  const { getOutletStockAnalysis, outletstockReportDetails } = outletStockAnalysisStore

  const [outletstockAnalysis, setOutletStockAnalysis] = useState<IOutletStockAnalysis>(new IOutletStockAnalysis());
  const [stockAnalysisList, setstockAnalysisList] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [isItemName, setItemName] = useState(false);
  const [isCategory, setCategory] = useState(false);
  const [isSubCategory, setSubCategory] = useState(false);
  const [isOutlet, setOutlet] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [errors, setErrors] = useState<any>({})

  // const filteredData = useMemo(() => {
  //   if (searchValue != '' && searchValue?.length > 0 && outletstockReportDetails?.length > 0) {
  //     return sortedData(outletstockReportDetails, 'outwardDate')?.filter((stock) => {
  //       if (stock?.itemname?.toLowerCase().includes(searchValue?.toLowerCase())) {
  //         return stock;
  //       }
  //     })
  //   } else {
  //     return [];
  //   }
  // }, [searchValue])


  const filteredData = useMemo(() => {
    if (searchValue !== '' && searchValue?.length > 0 && outletstockReportDetails?.length > 0) {
      // Filter data based on searchValue
      const filteredData = sortedData(outletstockReportDetails, 'outwardDate')?.filter((stock) =>
        stock?.itemname?.toLowerCase().includes(searchValue?.toLowerCase())
      );
  
      // Multi-field sorting
      return filteredData?.sort((a, b) => {
        if (a.itemid !== b.itemid) return a.itemid - b.itemid;
        if (a.barcode !== b.barcode) return a.barcode.localeCompare(b.barcode);
        if (a.batchno !== b.batchno) return a.batchno.localeCompare(b.batchno);
        if (a.Typeid !== b.Typeid) return a.Typeid - b.Typeid;
        return 0; // Fallback
      });
    } else {
      return [];
    }
  }, [searchValue, outletstockReportDetails]); // Dependency array


  const handleSubcategoryValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setOutletStockAnalysis({ ...outletstockAnalysis, subCategoryId: val.subCategoryId });
    }
  }

  function handleSearchInput(e) {
    setSearchValue(e.target.value);
  }

  const handleOutlet = async (name, val) => {
   
    if (val != null) {
      setOutletStockAnalysis({ ...outletstockAnalysis, outletId: val.outletid });
    }
  }
  const handleItemValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setOutletStockAnalysis({ ...outletstockAnalysis, itemId: val.itemId });
    }
  }

   

  const searchStockAnalysis = async (e) => {
    setLoading(true);
    let error: any = {}
    // error = StockAnalysisValidation(stockAnalysis)
    // if (Object.keys(error).length === 0) {
      const stockAnalysisList  = await getOutletStockAnalysis(outletstockAnalysis);
      setstockAnalysisList(Object.assign([], stockAnalysisList))
      console.log(stockAnalysisList,'stockAnalysisList>>');
      
      setErrors({})
    // } else {
      setErrors(error)
    // }
    setLoading(false);
  }


  

  const handleInputchnage = (e) => {
    const { name, value } = e.target;
    setOutletStockAnalysis({ ...outletstockAnalysis, [name]: value });
  };

  function handleChangeInput(event: any) {
    const { name, value } = event.target;
    if (name === 'offerType' && value === '') {
      setItemName(false);
      setCategory(false);
      setSubCategory(false);
    }
    if (value === 'ItemName') {
      setItemName(true);
      setCategory(false);
      setSubCategory(false);
    }
    if (value === 'Category') {
      setItemName(false);
      setCategory(true);
      setSubCategory(false);
    }
    if (value === 'SubCategory') {
      setItemName(false);
      setCategory(false);
      setSubCategory(true);
    }
    setOutletStockAnalysis({ ...outletstockAnalysis, [name]: value })
  }
  const exportExcelDataDetails = async (excelDataDetails) => {
console.log(excelDataDetails,'excelDataDetails>>');

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Results');

    worksheet.pageSetup = {
      fitToPage: true,
      fitToHeight: 1,
      fitToWidth: 1,
      orientation: 'landscape',
      paperSize: 9,
    };

  // Create subtitle strings

  const subtitleStart = `Date From : ${moment(outletstockAnalysis.fromDate).format('DD-MM-YYYY')} `;
  const subtitleEnd = `  To : ${moment(outletstockAnalysis.toDate).format('DD-MM-YYYY')}`;
  const subtitleCombined = `Report Generated on: ${moment(new Date().toLocaleDateString()).format('DD-MM-YYYY')}`; // Static string with current date

 

    worksheet.mergeCells('A1:Y1');
    const titleCell = worksheet.getCell('A1');
    titleCell.value = "GANGA SUPER MARKET";
    titleCell.font = { size: 17, bold: true };
    titleCell.alignment = { horizontal: 'center', vertical: 'middle' };

    worksheet.mergeCells('A2:Y2');
    const subtitleCell = worksheet.getCell('A2');
    subtitleCell.value = "OUTLET STOCK LEDGER " + subtitleStart + subtitleEnd;
    subtitleCell.font = { size: 13, bold: true };
    subtitleCell.alignment = { horizontal: 'center', vertical: 'middle' };


    worksheet.mergeCells('B5:E5');
    const subtitleRCell = worksheet.getCell('B5');
    subtitleRCell.value = subtitleCombined;
    subtitleRCell.font = { size: 13, bold: true };
    subtitleRCell.alignment = { horizontal: 'left', vertical: 'middle' };
    
    worksheet.addRow([]);

    const addBorders = (cell) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    };

    const addBordersToMergedCells = (worksheet, startCell, endCell) => {
      const startRow = parseInt(startCell[1]);
      const endRow = parseInt(endCell[1]);
      const startCol = startCell.charCodeAt(0) - 64;
      const endCol = endCell.charCodeAt(0) - 64;

      for (let row = startRow; row <= endRow; row++) {
        for (let col = startCol; col <= endCol; col++) {
          addBorders(worksheet.getCell(row, col));
        }
      }
    };

         //worksheet.mergeCells('B7:C7');
         const SubHeadCell0 = worksheet.getCell('B7');
         SubHeadCell0.value = "SNo";
         SubHeadCell0.font = { bold: true };
         SubHeadCell0.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'B7', 'B8');
     
              
        // worksheet.mergeCells('H7:I7');
         const SubHeadCell4 = worksheet.getCell('C7');
         SubHeadCell4.value = "vocdate";
         SubHeadCell4.font = { bold: true };
         SubHeadCell4.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'C7', 'C7');
     
            
         //worksheet.mergeCells('L7:M7');
         const SubHeadCell6 = worksheet.getCell('D7');
         SubHeadCell6.value = "membername";
         SubHeadCell6.font = { bold: true };
         SubHeadCell6.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'D7', 'D7');
     
         //worksheet.mergeCells('N7:O7');
         const SubHeadCell7 = worksheet.getCell('E7');
         SubHeadCell7.value = "itemid";
         SubHeadCell7.font = { bold: true };
         SubHeadCell7.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'E7', 'E7');
     
        // worksheet.mergeCells('I7:I7');
         const SubHeadCell8 = worksheet.getCell('F7');
         SubHeadCell8.value = "gsmcode";
         SubHeadCell8.font = { bold: true };
         SubHeadCell8.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'F7', 'F7');
     
         //worksheet.mergeCells('I7:I7');
         const SubHeadCell9 = worksheet.getCell('G7');
         SubHeadCell9.value = "itemname";
         SubHeadCell9.font = { bold: true };
         SubHeadCell9.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'G7', 'G7');
     
         //worksheet.mergeCells('I7:I7');
         const SubHeadCell10 = worksheet.getCell('H7');
         SubHeadCell10.value = "Hsncode";
         SubHeadCell10.font = { bold: true };
         SubHeadCell10.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'H7', 'H7');
     
         //worksheet.mergeCells('I7:I7');
         const SubHeadCell11 = worksheet.getCell('I7');
         SubHeadCell11.value = "Category";
         SubHeadCell11.font = { bold: true };
         SubHeadCell11.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'I7', 'I7');
     
         //worksheet.mergeCells('I7:I7');
         const SubHeadCell12 = worksheet.getCell('J7');
         SubHeadCell12.value = "subcategoryname";
         SubHeadCell12.font = { bold: true };
         SubHeadCell12.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'J7', 'J7');
     
         //worksheet.mergeCells('I7:I7');
         const SubHeadCell13 = worksheet.getCell('K7');
         SubHeadCell13.value = "brandname";
         SubHeadCell13.font = { bold: true };
         SubHeadCell13.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'K7', 'K7');
     
         
         const SubHeadCell15 = worksheet.getCell('L7');
         SubHeadCell15.value = "Barcode";
         SubHeadCell15.font = { bold: true };
         SubHeadCell15.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'L7', 'L7');
     
         const SubHeadCell16 = worksheet.getCell('M7');
         SubHeadCell16.value = " Batch No";
         SubHeadCell16.font = { bold: true };
         SubHeadCell16.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'M7', 'M7');
     
     
         const SubHeadCell171 = worksheet.getCell('N7');
         SubHeadCell171.value =  " Voc No";
         SubHeadCell171.font = { bold: true };
         SubHeadCell171.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'N7', 'N7');
     
         const SubHeadCell18 = worksheet.getCell('O7');
         SubHeadCell18.value ="Voc Type";
         SubHeadCell18.font = { bold: true };
         SubHeadCell18.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'O7', 'O7');
     
         const SubHeadCell14 = worksheet.getCell('P7');
         SubHeadCell14.value = "Unit Name";
         SubHeadCell14.font = { bold: true };
         SubHeadCell14.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'P7', 'P7');
     
         const SubHeadCell19 = worksheet.getCell('Q7');
         SubHeadCell19.value = " MRP";
         SubHeadCell19.font = { bold: true };
         SubHeadCell19.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'Q7', 'Q7');

         const SubHeadCell192 = worksheet.getCell('R7');
         SubHeadCell192.value = " Opening Qty";
         SubHeadCell192.font = { bold: true };
         SubHeadCell192.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'R7', 'R7');

         const SubHeadCell191 = worksheet.getCell('S7');
         SubHeadCell191.value = " ReceQty";
         SubHeadCell191.font = { bold: true };
         SubHeadCell191.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'S7', 'S7');
     
         const SubHeadCell20 = worksheet.getCell('T7');
         SubHeadCell20.value = "Recerate";
         SubHeadCell20.font = { bold: true };
         SubHeadCell20.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'T7', 'T7');
     
         const SubHeadCell21 = worksheet.getCell('U7');
         SubHeadCell21.value =" Recevalue";
         SubHeadCell21.font = { bold: true };
         SubHeadCell21.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'U7', 'U7');
     
         const SubHeadCell22 = worksheet.getCell('V7');
         SubHeadCell22.value =  " issqty";
         SubHeadCell22.font = { bold: true };
         SubHeadCell22.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'V7', 'V7');
     
         const SubHeadCell23 = worksheet.getCell('W7');
         SubHeadCell23.value = " issrate";
         SubHeadCell23.font = { bold: true };
         SubHeadCell23.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'W7', 'W7');
     
         const SubHeadCell24 = worksheet.getCell('X7');
         SubHeadCell24.value = " Amount";
         SubHeadCell24.font = { bold: true };
         SubHeadCell24.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'X7', 'X7');

         const SubHeadCell241 = worksheet.getCell('Y7');
         SubHeadCell241.value = " Closing Qty";
         SubHeadCell241.font = { bold: true };
         SubHeadCell241.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'Y7', 'Y7');
     
 
    const testColumnIndex = 5;
    const weightColumnIndex = 6;
    const widthInInches = 3;
    const widthInChars = widthInInches * 5.1;


    excelDataDetails.forEach((datadet, index) => {
      console.log(datadet,'datadet');
      
      const rowIndex = index + 8;

      const seqNoCell = worksheet.getCell(rowIndex, 2);
      seqNoCell.value = index + 1;
      seqNoCell.alignment = { horizontal: 'right', vertical: 'middle' };
      addBorders(seqNoCell);

     
      const BillnoNumberCell = worksheet.getCell(rowIndex, 3);
          BillnoNumberCell.value = datadet.vocdate;
          BillnoNumberCell.alignment = { horizontal: 'center', vertical: 'middle' };
          worksheet.getColumn(3).width = 15;
          addBorders(BillnoNumberCell);
     
    
          const BilltypeNumberCell = worksheet.getCell(rowIndex, 4);
          BilltypeNumberCell.value = datadet.membername;
          BilltypeNumberCell.alignment = { horizontal: 'center', vertical: 'middle' };
          worksheet.getColumn(4).width = 15;
          addBorders(BilltypeNumberCell);
    
          const Customer_TypeCell = worksheet.getCell(rowIndex, 5);
          Customer_TypeCell.value = datadet.itemid;
          Customer_TypeCell.alignment = { horizontal: 'center', vertical: 'middle' };
          worksheet.getColumn(5).width = 15;
          addBorders(Customer_TypeCell);
    
          const midCell = worksheet.getCell(rowIndex, 6);
          midCell.value = datadet.gsmcode;
          midCell.alignment = { horizontal: 'right', vertical: 'middle' };
          worksheet.getColumn(6).width = 12;
          addBorders(midCell);
    
          const membernameCell = worksheet.getCell(rowIndex, 7);
          membernameCell.value = datadet.itemname;
          membernameCell.alignment = { horizontal: 'left', vertical: 'middle' };
          worksheet.getColumn(7).width = 60;
          addBorders(membernameCell);
    
          const MobilenoCell = worksheet.getCell(rowIndex, 8);
          MobilenoCell.value = datadet.Hsncode;
          MobilenoCell.alignment = { horizontal: 'center', vertical: 'middle' };
          worksheet.getColumn(8).width = 15;
          addBorders(MobilenoCell);
    
          const itemidCell = worksheet.getCell(rowIndex, 9);
          itemidCell.value = datadet.Category;
          itemidCell.alignment = { horizontal: 'center', vertical: 'middle' };
          worksheet.getColumn(9).width = 30;
          addBorders(itemidCell);
           
          const itemnameCell = worksheet.getCell(rowIndex, 10);
          itemnameCell.value = datadet.subcategoryname;
          itemnameCell.alignment = { horizontal: 'left', vertical: 'middle' };
          worksheet.getColumn(10).width = 30;
          addBorders(itemnameCell);
    
          const CategoryCell = worksheet.getCell(rowIndex, 11);
          CategoryCell.value = datadet.brandname;
          CategoryCell.alignment = { horizontal: 'left', vertical: 'middle' };
          worksheet.getColumn(11).width = 20;
          addBorders(CategoryCell);
    
        
          const BarcodeCell = worksheet.getCell(rowIndex, 12);
          BarcodeCell.value = datadet.barcode;
          BarcodeCell.alignment = { horizontal: 'center', vertical: 'middle' };
          worksheet.getColumn(12).width = 20;
          addBorders(BarcodeCell);
    
          const batch_noCell = worksheet.getCell(rowIndex, 13);
          batch_noCell.value = datadet.batchno;
          batch_noCell.alignment = { horizontal: 'center', vertical: 'middle' };
          worksheet.getColumn(13).width = 20;
          addBorders(batch_noCell);
    
         
          const OutletnameNumberCell = worksheet.getCell(rowIndex, 14);
          OutletnameNumberCell.value = datadet.vocno;
          OutletnameNumberCell.alignment = { horizontal: 'left', vertical: 'middle' };
          worksheet.getColumn(15).width = 14;
          addBorders(OutletnameNumberCell);
    
          const QtyCell = worksheet.getCell(rowIndex, 15);
          QtyCell.value = datadet.ttype;
          QtyCell.alignment = { horizontal: 'left', vertical: 'middle' };
          worksheet.getColumn(15).width = 20;
          addBorders(QtyCell);

          const HSNCodeCell = worksheet.getCell(rowIndex, 16);
          HSNCodeCell.value = datadet.Unitname;
          HSNCodeCell.alignment = { horizontal: 'left', vertical: 'middle' };
          worksheet.getColumn(16).width = 15;
          addBorders(HSNCodeCell);
    
          const UnitnameCell1 = worksheet.getCell(rowIndex, 17);
          UnitnameCell1.value = datadet.mrprate;
          UnitnameCell1.alignment = { horizontal: 'center', vertical: 'middle' };
          worksheet.getColumn(17).width = 15;
          addBorders(UnitnameCell1);

          const UnitnameCel2 = worksheet.getCell(rowIndex, 18);
          UnitnameCel2.value = datadet.OpenQty;
          UnitnameCel2.alignment = { horizontal: 'center', vertical: 'middle' };
          worksheet.getColumn(18).width = 15;
          addBorders(UnitnameCel2);

          const UnitnameCell = worksheet.getCell(rowIndex, 19);
          UnitnameCell.value = datadet.ReceQty;
          UnitnameCell.alignment = { horizontal: 'center', vertical: 'middle' };
          worksheet.getColumn(19).width = 15;
          addBorders(UnitnameCell);
    
          const freeqtyCell = worksheet.getCell(rowIndex, 20);
          freeqtyCell.value = datadet.Recerate;
          freeqtyCell.alignment = { horizontal: 'right', vertical: 'middle' };
          worksheet.getColumn(20).width = 10;
          addBorders(freeqtyCell);
    
          const rateCell = worksheet.getCell(rowIndex, 21);
          rateCell.value = datadet.Recevalue;
          rateCell.alignment = { horizontal: 'right', vertical: 'middle' };
          worksheet.getColumn(21).width = 10;
          addBorders(rateCell);
    
                
          const DiscountPerCell = worksheet.getCell(rowIndex, 22);
          DiscountPerCell.value = datadet.issqty;
          DiscountPerCell.alignment = { horizontal: 'right', vertical: 'middle' };
          worksheet.getColumn(22).width = 15;
          addBorders(DiscountPerCell);
    
          const DiscountvalueCell = worksheet.getCell(rowIndex, 23);
          DiscountvalueCell.value = datadet.issrate;
          DiscountvalueCell.alignment = { horizontal: 'right', vertical: 'middle' };
          worksheet.getColumn(23).width = 15;
          addBorders(DiscountvalueCell);
    
    
          const CGSTPCell = worksheet.getCell(rowIndex, 24);
          CGSTPCell.value = datadet.Amount;
          CGSTPCell.alignment = { horizontal: 'right', vertical: 'middle' };
          worksheet.getColumn(24).width = 15;
          addBorders(CGSTPCell);

          const CGSTPCell1 = worksheet.getCell(rowIndex, 25);
          CGSTPCell1.value = datadet.ClosingQty;
          CGSTPCell1.alignment = { horizontal: 'right', vertical: 'middle' };
          worksheet.getColumn(25).width = 15;
          addBorders(CGSTPCell1);
      
      
      const lastRow = worksheet.lastRow?.number;
      // const sumamount1 = `SUM(V7:V${lastRow})`
      // const sumdiscount2 = `SUM(X7:X${lastRow})`
      // const sumcgst3 = `SUM(Z7:Z${lastRow})`
      // const sumsgst4 = `SUM(AB7:AB${lastRow})`
      // const sumtotal5 = `SUM(AE7:AE${lastRow})`
      // const suminvoice6 = `SUM(AF7:AF${lastRow})`
      // const sumcash7 = `SUM(AG7:AG${lastRow})`
      // const sumreadm8 = `SUM(AH7:AH${lastRow})`
      // const sumreturn9 = `SUM(AI7:AI${lastRow})`
      // const sumcard10 = `SUM(AJ7:AJ${lastRow})`
      // const sumqrcode11 = `SUM(AK7:AK${lastRow})`
      // const sumonline12 = `SUM(AL7:AL${lastRow})`
      // const sumbanktr13 = `SUM(AM7:AM${lastRow})`

      //  worksheet.getCell(`Q${Number(lastRow) + 1}`).value = 'Total';

      //   worksheet.getCell(`V${Number(lastRow) + 1}`).value = { formula: sumcash7 };
      //   worksheet.getCell(`X${Number(lastRow) + 1}`).value = { formula: sumdiscount2 };
      //   worksheet.getCell(`Z${Number(lastRow) + 1}`).value = { formula: sumcgst3 };
      //   worksheet.getCell(`AB${Number(lastRow) + 1}`).value = { formula: sumsgst4 };
      //   worksheet.getCell(`AE${Number(lastRow) + 1}`).value = { formula: sumtotal5 };
      //   worksheet.getCell(`AF${Number(lastRow) + 1}`).value = { formula: suminvoice6 };
      //   worksheet.getCell(`AG${Number(lastRow) + 1}`).value = { formula: sumcash7 };
      //   worksheet.getCell(`AH${Number(lastRow) + 1}`).value = { formula: sumreadm8 };
      //   worksheet.getCell(`AI${Number(lastRow) + 1}`).value = { formula: sumreturn9 };
      //   worksheet.getCell(`AJ${Number(lastRow) + 1}`).value = { formula: sumcard10 };
      //   worksheet.getCell(`AK${Number(lastRow) + 1}`).value = { formula: sumqrcode11 };
      //   worksheet.getCell(`AL${Number(lastRow) + 1}`).value = { formula: sumonline12 };
      //   worksheet.getCell(`AM${Number(lastRow) + 1}`).value = { formula: sumbanktr13 };
   

 
      
      // const finalScoreCell = worksheet.getCell(rowIndex, 41);
      // finalScoreCell.alignment = { horizontal: 'center', vertical: 'middle' };
      // addBorders(finalScoreCell);
      // worksheet.getColumn(41).width = 15;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, `Outletstock_Report${new Date().toISOString().split('T')[0]}.xlsx`);

  }
  async function createExportExcelObj() {
    
    const salesRegisterData =await getOutletStockAnalysis(outletstockAnalysis);
    console.log(salesRegisterData, 'salesRegisterData>>');
    
    exportExcelDataDetails(salesRegisterData)
   
  }


  // function createExportExcelObj(stockReportDetails: any[]) {
  //   let excelArr: ExcelSheetData[] = stockReportDetails?.map((stockReport) => {
  //     const excelObj: ExcelSheetData = {
  //       ttype: stockReport?.ttype,
  //       vocno: stockReport?.vocno,
  //       vocdate: stockReport?.outwardDate ? moment(stockReport?.vocdate)?.format('DD-MMM-YYYY') : '',
  //       membername: stockReport?.membername,
  //       itemid: stockReport?.itemid,
  //       gsmcode: stockReport?.gsmcode,
  //       itemname: stockReport?.itemname,
  //       Hsncode: stockReport?.Hsncode,
  //       Category: stockReport?.Category,
  //       subcategoryname: stockReport?.subcategoryname,
  //       Unitname: stockReport?.Unitname,
  //       barcode: stockReport?.barcode,
  //       batchno: stockReport?.batchno,
  //       rackNo: stockReport?.rackNo,
  //       BrandType: stockReport?.BrandType,
  //       ReceQty: stockReport?.ReceQty,
  //       Recerate: stockReport?.Recerate,
  //       Recevalue: stockReport?.Recevalue,
  //       issqty: stockReport?.issqty,
  //       issrate: stockReport?.issrate,
  //       Amount: stockReport?.Amount,
  //     }
  //     return excelObj;
  //   })
  //   exportToExcel(excelArr)
  // }

  function exportToExcel(excelArr: ExcelSheetData[]) {
    const csvContent = ["Outlet Stock Analysis", Object.keys(excelArr[0]).join(','), ...excelArr?.map(obj => Object.values(obj).join(','))].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Outlet Stock Analysis ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async function fetchPreLoadngData() {
    await loadItemConversion();
    await loadOutlet();
    await loadSubcategory();
    // searchStockAnalysis()
    setLoading(false);
  }

  console.log(loadOutlet, 'loadOutlet');

  const isCurrentPage = useRef(true)

  useEffect(() => {
   
    if (isCurrentPage?.current) {

      fetchPreLoadngData();
      // loadOutlet();

      isCurrentPage.current = false;
    }
    return () => {

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (outletstockReportDetails?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(outletstockReportDetails)
    }
  }, [outletstockReportDetails])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(sortedData(outletstockReportDetails, 'outwardDate'), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>

      <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <div className='' style={{ width: '100%' }}>
          <div className='vertical-space-20'></div>
          <div className='outletInputField inputFormBox LgInputField'>
            <div className='hrBox'>
              <h3>Outlet Stock Analysis</h3>
            </div>
            {isLoading ? <ProgressBar /> : <></>}
            <div className='inputBoxLists'>
              <div className='ItemInwardInputBox'>
                <div className='row'>
                  <div className='col-sm-1'>
                    <div className='inputBox'>
                      <label style={{ fontSize: "small" }}>From Date <span>*</span></label>
                      <input type="date" style={{ width: "100%" }}
                        value={outletstockAnalysis.fromDate}
                        onChange={handleInputchnage}
                        name="fromDate"
                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-sm-1'>
                    <div className='inputBox'>
                      <label style={{ fontSize: "small" }}>To Date <span>*</span></label>
                      <input type="date" style={{ width: "100%" }}
                        value={outletstockAnalysis.toDate}
                        onChange={handleInputchnage}
                        name="toDate"
                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                    </div>
                    <div className='vertical-space-20'></div>
                  </div>
                  <div className="col-sm-2" style={{ marginTop: '18px' }}>

                    <div className='inputBox'>

                      {/* <AutoComplete getOptionLabel='outletName'
                        value={loadOutlet.find((option) => option?.outletid == outletstockAnalysis?.outletId)?.outletName}
                        options={loadOutlet} placeholder='Select Outlet Name..'
                        emitOption={(option: any) => handleOutlet(option, '')} /> */}
                      <AutoComplete placeholder='Select Outlet..' clsName='full-width' getOptionLabel='outletName'
                        emitOption={(option: any) => handleOutlet('', option)} options={loadOutlets} />
                    </div>
                    {errors.outletId && <p style={{ color: 'red' }}>{errors.outletId}</p>}
                  </div>
                  <div className="col-sm-2" style={{ marginTop: '17px' }}>
                    <div className="input-group mb-3">
                      <input type="text"
                        style={{ height: "47px" }}
                        name='searchValue'
                        value={searchValue}
                        onChange={handleSearchInput}
                        className="form-control" placeholder="Search Here.."
                        aria-label="Search Outlet..."
                        aria-describedby="search-button"
                      ></input>
                      <div className="input-group-append">
                        <button style={{ height: "47px" }} className="btn btn-outline-secondary" type="button"
                          id="search-button">
                          <FontAwesomeIcon icon={faSearch}
                          // onClick={handleQuoteSearch}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-2'>
                    <div className='inputBox' style={{ marginTop: '17px' }}>
                      <label></label>
                      <select style={{ paddingRight: "0px", height: "47px" }} className="form-selected" id="Mode" name="searchfield" onChange={handleChangeInput} >
                        <option value="">Select Search Criteria</option>
                        <option value="ItemName">Item Name</option>
                        <option value="Category">CateGory </option>
                        <option value="SubCategory">Sub Category </option>
                        {/* <option value="Outlet">Outlet </option> */}
                      </select>
                    </div>

                  </div>
                  <div className='col-sm-2' style={{ marginTop: '17px' }}>
                    {
                      isItemName ?
                        <>
                          <div className='inputBox' style={{ marginLeft: "16px" }}>
                            <Autocomplete size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={loadItemConversions}
                              getOptionLabel={(option: any) => option.itemName}
                              onChange={(event, val) => handleItemValue('itemId', event, val)}
                              renderInput={(params: any) =>
                                <TextField  {...params}
                                  id="outlined-size-small"
                                  color='info'
                                  size="small"
                                  type="text"
                                  placeholder='Select Item..'
                                  name='itemId'
                                />}
                            />
                          </div>
                          <div className='vertical-space-10'></div>
                        </> : null

                    }
                    {
                      isCategory ?
                        <>
                          <div className='inputBox'>
                            <select className="form-selected" onChange={handleInputchnage} style={{ width: "18ch", marginLeft: "17px" }} id="Category" name="category">
                              <option value="" selected>Not Select</option>
                              <option value="Home&amp;Kitchen">Home &amp; Kitchen</option>
                              <option value="Pets">Pets</option>
                              <option value="Beauty&amp;Health">Beauty &amp; Health</option>
                              <option value="Grocery">Grocery</option>
                            </select>
                          </div>
                          <div className='vertical-space-10'></div>
                        </> : null
                    }
                    {
                      isSubCategory ?
                        <>
                          <div className='inputBox' style={{ marginLeft: "16px" }}>
                            <Autocomplete size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={loadSubcategories}
                              getOptionLabel={(option: any) => option.subCategoryName}
                              onChange={(event, val) => handleSubcategoryValue('subCategoryId', event, val)}
                              renderInput={(params: any) =>
                                <TextField  {...params}
                                  id="outlined-size-small"
                                  color='info'
                                  size="small"
                                  type="text"
                                  placeholder='Select Subcategory..'
                                  name='subcategory'
                                />}
                            />

                          </div>
                          <div className='vertical-space-10'></div>
                        </> : null
                    }
                    {
                      isOutlet ?
                        <>
                          <div className='inputBox'>
                            <Autocomplete size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={loadOutlets}
                              getOptionLabel={(option: any) => option.outletName}
                              onChange={(event, val) => handleOutlet(val, '')}
                              renderInput={(params: any) =>
                                <TextField  {...params}
                                  id="outlined-size-small"
                                  color='info'
                                  size="small"
                                  type="text"
                                  placeholder='Select Outlet'
                                  name='outletId'
                                />}
                            />
                          </div>
                          <div className='vertical-space-10'></div>
                        </> : null
                    }
                  </div>
                  <div className="col-sm-1" style={{ marginTop: '24px' }}>
                    <button className='secondaryBtn' type='submit' onClick={searchStockAnalysis}>Generate</button>
                  </div>
                  <div className="col-sm-1" style={{ marginTop: '24px' }}>
                    <button className='secondaryBtn' onClick={() => createExportExcelObj()}>Excel</button>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-5'>
                  <div className='btnBox'>
                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{outletstockReportDetails?.length} Records</button>
                  </div>
                </div>
                <div className=' col-sm-7' >
                  <Stack spacing={2}>
                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                  </Stack>
                </div>
              </div>

              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">SNo</th>
                      <th scope="col">Voucher Date</th>
                      <th scope="col">Member Name</th>
                      <th scope="col">Item Id</th>
                      <th scope="col">Gsmcode</th>
                      <th scope="col">Item Name</th>
                      <th scope="col">Hsncode</th>
                      <th scope="col">Category</th>
                      <th scope="col">Sub Category Name</th>
                      <th scope="col">Brand Name</th>
                      <th scope="col">Barcode</th>
                      <th scope='col'>Batch No</th>
                      <th scope='col'>Voc No</th>
                      <th scope='col'>Voc Type</th>
                      <th scope="col">Unit Name</th>
                      <th scope="col">MRP</th>
                      <th scope="col">Opening Qty</th>
                      <th scope="col">Received Qty</th>
                      <th scope="col">Receive Value</th>
                      <th scope="col">Issued Qty</th>
                      <th scope="col">Issued Value</th>
                      <th scope="col">Closing Qty</th>

                    </tr>
                  </thead>
                  <tbody>

                    {(searchValue?.length > 0 ? filteredData : currentPageData).length > 0 ?
                      (searchValue?.length > 0 ? filteredData : currentPageData)?.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.id} </td>
                            <td>{moment(val.vocdate).format('DD-MMM-YYYY')}</td>
                            <td>{val.membername} </td>
                            <td>{val.itemid} </td>
                            <td>{val.gsmcode} </td>
                            <td>{val.itemname} </td>
                            <td>{val.Hsncode} </td>
                            <td>{val.Category} </td>
                            <td>{val.subcategoryname} </td>
                            <td>{val.brandname} </td>
                            <td>{val.barcode}</td>
                            <td>{val.batchno}</td>
                            <td>{val.vocno}</td>
                            <td>{val.ttype}</td>
                            <td>{val.Unitname} </td>
                            <td>{val.Mrprate} </td>
                            <td>{val.OpenQty}</td>
                            <td>{val.ReceQty}</td>
                            <td>{val.Recevalue} </td>
                            <td>{val.issqty} </td>
                            <td>{val.Amount}</td>
                            <td>{val.ClosingQty}</td>
                          </tr>
                        )
                      }) : <h6> No Data Available</h6>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default OutletStockAnalysis;
