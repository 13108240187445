export class IOutletReturnForm {
    doM_EntryNo?: number = 0
    doM_SupplierId?: number = 0
    doM_Date?: string = new Date().toISOString().substr(0, 10)
    doM_DebitNoteNo?: string = "0"
    doM_DebitType?: string = ""
    rtnamount?: number = 0
    damageoutlettransferdet?: IOutletTransferDet[] = []
}

export class IOutletTransferDet {
    doT_OUTID?: number = 0
    doT_ItemID?: number = 0
    doT_Qty?: number = 0
    doT_Rate?: number = 0
    doT_TotalAmt?: number = 0
    doT_Reason?: string = ""
    doT_Type?: string = ""
    doT_damageentrytransfermstid?: number = 0
    isChecked?: boolean = false
    master_id?: number = 0
    doT_Barcode?: string = ""
    doT_Batchno?: string = ""
    doT_damageentrytransferdid?: number = 0
    doT_supplierId?: number = 0
}